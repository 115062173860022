import { MaritalStatusDto, PersonalDetailDto } from 'src/app/services/property-matrixV2/models';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-add-edit-natural-person',
  templateUrl: './add-edit-natural-person.component.html',
  styleUrls: ['./add-edit-natural-person.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AddEditNaturalPersonComponent implements OnInit {

  naturalPersonForm: FormGroup;

  maritalStatuses: MaritalStatusDto[] = [];
  selectedMaritalStatus: MaritalStatusDto;

  constructor(
    private _formBuilder: FormBuilder,
    private _lookupService: LookupService,
    public _dialogRef: MatDialogRef<AddEditNaturalPersonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonalDetailDto
  ) { }

  ngOnInit(): void {
    this.fetchMaritalStatuses();

    this.naturalPersonForm = this._formBuilder.group({
      fullNames: [this.data?.fullNames || '', [Validators.required, Validators.maxLength(250)]],
      surname: [this.data?.surname || '', [Validators.required, Validators.maxLength(250)]],
      mobileNumber: [this.data?.mobileNumber || '', [Validators.required, Validators.maxLength(10), CustomValidators.mobileValidator()]],
      emailAddress: [this.data?.emailAddress || '', [Validators.required, CustomValidators.emailValidator(), Validators.maxLength(250)]],
      confirmEmail: [this.data?.confirmEmail || '', [Validators.required, CustomValidators.emailValidator(), Validators.maxLength(250)]],
      isRsaResident: [this.data?.isRsaResident ?? null],
      idNumber: [this.data?.idNumber || '', [Validators.maxLength(13)]],
      passportNumber: [this.data?.passportNumber || '', [Validators.maxLength(20)]],
      maritalStatus: [this.data?.maritalStatus || '', [Validators.required]],
      spouseDetail: this._formBuilder.group({
        fullNames: [this.data?.spouseDetail?.fullNames || ''],
        surname: [this.data?.spouseDetail?.surname || ''],
        mobileNumber: [this.data?.spouseDetail?.mobileNumber || ''],
        emailAddress: [this.data?.spouseDetail?.emailAddress || ''],
        confirmEmail: [this.data?.spouseDetail?.confirmEmail || ''],
        isSpouseRsaResident: [this.data?.spouseDetail?.isSpouseRsaResident ?? null],
        idNumber: [this.data?.spouseDetail?.idNumber || '', [Validators.maxLength(13)]],
        passportNumber: [this.data?.spouseDetail?.passportNumber || '', [Validators.maxLength(20)]]
      }),
      confirmedConsent: [this.data?.confirmedConsent || false, [Validators.required, CustomValidators.mustBeTrue()]]
    }, { validators: CustomValidators.emailMatchValidator() });

    this.naturalPersonForm.get('maritalStatus').valueChanges.subscribe(value => {
      const spouseDetailGroup = this.naturalPersonForm.get('spouseDetail') as FormGroup;
      if (value?.intValue === 3) {
        spouseDetailGroup.get('fullNames').setValidators([Validators.required, Validators.maxLength(250)]);
        spouseDetailGroup.get('surname').setValidators([Validators.required, Validators.maxLength(250)]);
        spouseDetailGroup.get('mobileNumber').setValidators([Validators.required, Validators.maxLength(10), CustomValidators.mobileValidator()]);
        spouseDetailGroup.get('emailAddress').setValidators([Validators.required, CustomValidators.emailValidator(), Validators.maxLength(250)]);
        spouseDetailGroup.get('confirmEmail').setValidators([Validators.required, CustomValidators.emailValidator(), Validators.maxLength(250)]);
        spouseDetailGroup.updateValueAndValidity();
      } else {
        spouseDetailGroup.get('fullNames').clearValidators();
        spouseDetailGroup.get('surname').clearValidators();
        spouseDetailGroup.get('mobileNumber').clearValidators();
        spouseDetailGroup.get('emailAddress').clearValidators();
        spouseDetailGroup.get('confirmEmail').clearValidators();
        spouseDetailGroup.updateValueAndValidity();
      }
    });

    this.naturalPersonForm.get('isRsaResident').valueChanges.subscribe(value => {
      if (value === true) {
        this.naturalPersonForm.get('idNumber').setValidators([Validators.required, Validators.maxLength(13)]);
        this.naturalPersonForm.get('passportNumber').clearValidators();
      } else if (value === false) {
        this.naturalPersonForm.get('passportNumber').setValidators([Validators.required, Validators.maxLength(20)]);
        this.naturalPersonForm.get('idNumber').clearValidators();
      }
      this.naturalPersonForm.get('idNumber').updateValueAndValidity();
      this.naturalPersonForm.get('passportNumber').updateValueAndValidity();
    });

    this.naturalPersonForm.get('spouseDetail.isSpouseRsaResident').valueChanges.subscribe(value => {
      if (value === true) {
        this.naturalPersonForm.get('spouseDetail.idNumber').setValidators([Validators.required, Validators.maxLength(13)]);
        this.naturalPersonForm.get('spouseDetail.passportNumber').clearValidators();
      } else if (value === false) {
        this.naturalPersonForm.get('spouseDetail.passportNumber').setValidators([Validators.required, Validators.maxLength(20)]);
        this.naturalPersonForm.get('spouseDetail.idNumber').clearValidators();
      }
      this.naturalPersonForm.get('spouseDetail.idNumber').updateValueAndValidity();
      this.naturalPersonForm.get('spouseDetail.passportNumber').updateValueAndValidity();
    });

    if (this.data) {
      this.patchFormValues(this.data);
    }
  }

  private patchFormValues(data: PersonalDetailDto): void {
    this.naturalPersonForm.patchValue({
      fullNames: data?.fullNames,
      surname: data?.surname,
      mobileNumber: data?.mobileNumber,
      emailAddress: data?.emailAddress,
      confirmEmail: data?.confirmEmail,
      isRsaResident: data?.isRsaResident,
      idNumber: data?.idNumber,
      passportNumber: data?.passportNumber,
      maritalStatus: data?.maritalStatus,
      spouseDetail: {
        fullNames: data?.spouseDetail?.fullNames,
        surname: data?.spouseDetail?.surname,
        mobileNumber: data?.spouseDetail?.mobileNumber,
        emailAddress: data?.spouseDetail?.emailAddress,
        confirmEmail: data?.spouseDetail?.confirmEmail,
        isSpouseRsaResident: data?.spouseDetail?.isSpouseRsaResident,
        idNumber: data?.spouseDetail?.idNumber,
        passportNumber: data?.spouseDetail?.passportNumber
      },
      confirmedConsent: data?.confirmedConsent
    });
  }

  onMaritalStatusChange(event: MatRadioChange): void {
    this.selectedMaritalStatus = event.value;
  }

  private fetchMaritalStatuses(): void {
    this._lookupService.apiV1LookupGetMaritalStatusGet().subscribe({
      next: (response: MaritalStatusDto[]) => {
        this.maritalStatuses = response.sort((a, b) => a.intValue - b.intValue);
      }
    });
  }

  get isRsaResident(): boolean {
    return this.naturalPersonForm.get('isRsaResident').value;
  }

  get isSpouseRsaResident(): boolean {
    return this.naturalPersonForm.get('spouseDetail.isSpouseRsaResident').value;
  }

  onSubmit(): void {
    if (this.naturalPersonForm.valid) {
      const isNew = !this.data?.id;
      const result: PersonalDetailDto = {
        ...this.data,
        ...this.naturalPersonForm.value,
        spouseDetail: { ...this.naturalPersonForm.value.spouseDetail },
        index: isNew ? this.data?.index : this.data?.index
      };
      this._dialogRef.close(result);
    }
  }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
