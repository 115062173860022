<main class="main-container">
    <div class="container-fluid">
        <div class="text-center heading-1 pb-4">
            ABOUT US
        </div>
        <div class="text-center paragraph pb-4">
            Welcome to Property Matrix, a <b>nationwide network</b> of distinguished professionals in
        </div>
        <div class="text-center paragraph pb-4">
            Urban Planning
            <span class="burnt-red">|</span> Development Economics
            <span class="burnt-red">|</span> Property Valuation
            <span class="burnt-red">|</span> Real Estate
            <span class="burnt-red">|</span> Bond Origination
            <span class="burnt-red">|</span> Conveyancing
        </div>
        <div class="text-center heading-2 coral-text pb-4">
            LAUNCHING IN 2025
        </div>
        <div class="text-center paragraph pb-5">
            <b class="matrix-text">“matrix” </b>
            is defined as something that constitutes the place or point from which something else originates, takes
            form, or develops: Partners of Property Matrix will experience a revolutionary platform that elevates the
            property industry and their own network to new heights of connectivity, professionalism and empowerment.
        </div>
        <div class="paragraph pb-5">
            <div class="row col-12 pb-5">
                <div class="col-1 number">
                    <span>1</span>
                </div>
                <div class="col-11 number-paragraph">
                    <span>
                        We offer <b>reliable data</b>. Each property advertised on this platform is backed by a planning
                        report, endorsed by a professional town planner - registered with the South African Council of
                        Planners (SACPLAN). Information is based on an interpretation of the municipality's land-use
                        schemes, strategic plans and development policies. The report is substantiated with essential
                        planning data such as land use rights (“zoning”), Surveyor General data (diagrams for boundaries
                        and servitudes), deeds data, and where applicable, <b>development potential</b>: ways to
                        develop, extend or enhance the value of the property.
                    </span>
                </div>
            </div>
            <div class="row col-12 pb-5">
                <div class="col-1 number">
                    <span>2</span>
                </div>
                <div class="col-11 number-paragraph">
                    <span>
                        Our database establishes unparalleled connections between the supply and demand of property. The
                        extensive range of <b>unique property types</b> (land uses) sets us apart from existing property
                        platforms. From business to beauty, retail to recreation, education to sports facilities, and
                        more: we offer an unmatched selection for buyers to choose from.
                    </span>
                </div>
            </div>
            <div class="row col-12 pb-5">
                <div class="col-1 number">
                    <span>3</span>
                </div>
                <div class="col-11 number-paragraph">
                    <span>
                        <b>Our mission</b> is to establish a collaborative network of multi-disciplined professionals
                        that offers reliable property data, resulting in expedited property sales and informed
                        decision-making. We treat all our clients with an ethos of high standards and professionalism,
                        making everything about us truly about you.
                    </span>
                </div>
            </div>
        </div>
        <div class="text-center paragraph pb-5">
            <div class="heading-2 pb-5">
                HERE'S HOW WE CAN SERVE YOU:
            </div>
            <table>
                <tr class="heading-3">
                    <th>Sell it</th>
                    <th>Buy it</th>
                    <th>Speculate with it</th>
                    <th>Develop it</th>
                </tr>
                <tr>
                    <td>
                        We conduct a thorough planning investigation on your property (and its potential), advertise
                        your property, including the report, on our website, free of charge, and instantly notify all
                        buyers in our network who desire what your property offers.
                    </td>
                    <td>
                        We help you find the ideal property that suits your needs or business requirements. We offer
                        reliable property data, ensuring a discerning and transparent deal. We can also assist in
                        structuring suitable conditions to add to your offer to purchase contract.
                    </td>
                    <td>
                        Property Matrix provides a planning investigation on the property and its potential, advising
                        you on optimal enhancement or development strategies to maximize your return on investment. We
                        can also conduct a market gap analysis to identify your target market.
                    </td>
                    <td>
                        Our team inspects possible zoning, height, density, and coverage for the site, along with
                        council's strategic frameworks and plans, to advise you on the required processes, time-frames,
                        and figures for legal rights. We also conduct feasibility studies, calculating the expected ROI.
                    </td>
                </tr>
            </table>
        </div>
        <div class="heading-2 text-center pb-4 pt-5">
            PARTNERSHIP AND COLLABORATION
        </div>
        <div class="paragraph text-center pb-4">
            <b>Property Matrix </b>
            is an all-inclusive network. Select your profession below for further details and benefits.
        </div>
        <div class="partnership-type-block-container">
            <div *ngFor="let type of partnershipTypes" (click)="onTypeClick(type)" class="partnership-type-block my-5">
                <span class="heading-3">
                    {{ type }}
                </span>
            </div>
        </div>
    </div>
</main>