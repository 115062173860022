<mat-card class="mt-3" *ngFor="let scope of potentialConstructionScopes; let i = index" class="mt-2">
    <app-potential-land-parcel [scope]="scope" [index]="i" [amountOfLandParcels]="amountOfLandParcels"
        [substituteUsableArea]="potentialUsableProperty" (scopeChange)="onScopeChange(i, $event)">
    </app-potential-land-parcel>
</mat-card>
<mat-card *ngIf="amountOfLandParcels > 3" class="mt-2">
    <div class="row paragraph">
        <div class="col-12">
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="potentialConstructionScopeParagraphText"
                (ngModelChange)="onPotentialConstructionScopeParagraphChange($event)">
            </textarea>
        </div>
    </div>
</mat-card>