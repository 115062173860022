<main class="main-container">
    <div class="row paragraph mt-3">
        <div class="col-12 heading-2">
            Step 4: Sign Contract
        </div>
    </div>
    <div class="section-container">
        <div class="row paragraph mt-3">
            <p>
                Thank you for your time. Please keep an eye on your inbox.
            </p>
            <p>
                An email with a service level agreement contract will be sent to you once your application is
                successfully completed.
            </p>
        </div>
    </div>
    <div class="mt-5">
        <button class="button" type="submit" (click)="onSubmit()">NOTED</button>
    </div>
</main>