<main class="main-container">
    <form [formGroup]="extendedNetworksForm" (ngSubmit)="onSubmit()">
        <div class="content-padding">
            <div class="row paragraph mt-3">
                <div class="col-12 heading-2">
                    Step 2: Extended Network
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-10">
                    To ensure comprehensive assessments, our property reports, often supported by specialist studies and
                    information, require the involvement of extended professionals. Nomination of specialists can lead
                    to additional work opportunities stemming from technical queries on specific aspects.
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-10">
                    Our office will contact your nominated professionals individually if additional details are
                    required. All communication to specialists regarding properties you are involved in, will include
                    you or copy you. We will respect your role as the coordinator.
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-10">
                    <b>Important:</b> If the architect you work with does not provide drafting services (building plans
                    or “as-built” plans), please also provide details of an architectural technologist.
                </div>
            </div>
            <div class="row paragraph mt-5">
                <h4>Add and edit professionals</h4>
                <label>Kindly add professionals forming part of your network (click on plus icon below).</label>
            </div>
            <mat-card class="table-container mt-2">
                <div class="add-professional paragraph col-12">
                    <button class="add-user-button" type="button" (click)="addProfessional()">
                        <mat-icon class="add-user-icon bold">add</mat-icon>
                        <span class="add-user-label">Add professional</span>
                    </button>
                </div>
                <mat-table [dataSource]="dataSource" class="professional-table">
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>

                    <ng-container matColumnDef="number">
                        <mat-header-cell *matHeaderCellDef class="header-cell">
                            Number
                        </mat-header-cell>
                        <mat-cell *matCellDef="let professional">
                            {{ professional.index }}.
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="professional">
                        <mat-header-cell *matHeaderCellDef class="header-cell">
                            Professional
                        </mat-header-cell>
                        <mat-cell *matCellDef="let professional">
                            {{ professional.name + ' ' + professional.surname }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="profession">
                        <mat-header-cell *matHeaderCellDef class="header-cell">
                            Profession
                        </mat-header-cell>
                        <mat-cell *matCellDef="let professional">
                            {{ professional.profession?.value }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <mat-header-cell *matHeaderCellDef class="header-cell">
                            Edit
                        </mat-header-cell>
                        <mat-cell *matCellDef="let professional">
                            <button class="table-button" type="button" (click)="editProfessionalItem(professional)">
                                <mat-icon class="table-button-icon material-icons-outlined">edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef class="header-cell">
                            Delete
                        </mat-header-cell>
                        <mat-cell *matCellDef="let professional">
                            <button class="table-button" type="button" (click)="deleteProfessionalItem(professional)">
                                <mat-icon class="table-button-icon material-icons-outlined">delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                </mat-table>
            </mat-card>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="extendedNetworksForm.invalid">SUBMIT</button>
            </div>
        </div>
    </form>
</main>