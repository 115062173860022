import { PropertyListingStateService } from 'src/app/shared/services/sell-your-property/property-listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SellMyPropertyService } from 'src/app/services/property-matrixV2/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-listing-steps',
  templateUrl: './property-listing-steps.component.html',
  styleUrls: ['./property-listing-steps.component.scss']
})
export class PropertyListingStepsComponent implements OnInit {

  pageNumber: number = 1;
  visitedPages: number[] = [1];
  loading: boolean = false;

  isProgressLockEnabled: boolean = true;

  steps = [
    { label: 'Ownership Details', link: 1 },
    { label: 'Property Details', link: 2 },
    { label: 'Contract Details', link: 3 },
    { label: 'Upload Documents', link: 4 }
  ];

  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _notificationService: NotificationService,
    private _sellMyPropertyService: SellMyPropertyService,
    private _propertyListingStateService: PropertyListingStateService
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
    window.addEventListener('beforeunload', this.confirmPageExit);
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.confirmPageExit);
  }

  confirmPageExit(event: BeforeUnloadEvent): string {
    event.preventDefault();
    return '';
  }

  unlockNextPage() {
    if (!this.visitedPages.includes(this.pageNumber + 1)) {
      this.visitedPages.push(this.pageNumber + 1);
    }
  }

  selectPage(pageNumber: number) {
    if (this.isProgressLockEnabled && !this.visitedPages.includes(pageNumber)) {
      this._snackBar.open('Please submit the form to proceed to the next step.', 'Close', {
        duration: 3000
      });
      return;
    }
    this.pageNumber = pageNumber;
    GlobalMethods.scrollToTop();
  }

  changePage(page: number) {
    if (this.isProgressLockEnabled && !this.visitedPages.includes(page)) {
      return;
    }
    this.pageNumber = page;
    GlobalMethods.scrollToTop();
  }

  onDocumentSubmitted(): void {
    this.createPropertyListing();
  }

  private createPropertyListing(): void {
    this.loading = true;
    const formState: any = this._propertyListingStateService.getFormState();

    this._sellMyPropertyService.apiV1SellMyPropertySaveSellingMyPropertyFormPost({
      body: formState
    }).subscribe({
      next: () => {
        this.loading = false;
        this._router.navigateByUrl('');
        this._notificationService.showSuccessMessage('Success', 'Planner registration was successful.');
      },
      error: (_error: any) => {
        this.loading = false;
        this._notificationService.showErrorMessage('Error', 'An error occurred while registering planner.');
      }
    });
  }

  onFormSubmitted() {
    this.unlockNextPage();
    this.changePage(this.pageNumber + 1);
  }
}
