import { SacplanRegistrationTypeDto, UrbanPlannerDocumentDto, UrbanPlannerDto, UrbanPlannerExtendedNetworkDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrbanPlannerRegistrationStateService {

  private formState = new BehaviorSubject<UrbanPlannerDto>({
    fullSacplanRegistrationNumber: null,
    information: null,
    extendedNetworks: [],
    documents: [],
    userId: localStorage.getItem('userId') || null
  });

  formState$ = this.formState.asObservable();

  setUserId(userId: string) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      userId: userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber,
    });
    localStorage.setItem('userId', userId);
  }

  setSacplanRegistrationNumber(sacplanRegistrationNumber: string) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      fullSacplanRegistrationNumber: sacplanRegistrationNumber,
      userId: currentState.userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType
    });
  }

  setSacplanRegistrationTypeId(sacplanRegistrationType: SacplanRegistrationTypeDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      sacplanRegistrationType: sacplanRegistrationType,
      userId: currentState.userId,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber
    });
  }

  updateInformation(information: UrbanPlannerDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      information: information,
      userId: currentState.userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber
    });
  }

  updateExtendedNetwork(extendedNetworks: UrbanPlannerExtendedNetworkDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      extendedNetworks: extendedNetworks,
      userId: currentState.userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber
    });
  }

  updateDocuments(documents: UrbanPlannerDocumentDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      documents: documents,
      userId: currentState.userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber
    });
  }

  updateDocument(index: number, document: UrbanPlannerDocumentDto) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = document;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments,
      userId: currentState.userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber
    });
  }

  removeDocument(index: number) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = null;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments,
      userId: currentState.userId,
      sacplanRegistrationType: currentState.sacplanRegistrationType,
      fullSacplanRegistrationNumber: currentState.fullSacplanRegistrationNumber
    });
  }

  getFormState(): UrbanPlannerDto {
    return this.formState.value;
  }

  constructor() { }
}
