import { UrbanPlannerRegistrationStateService } from 'src/app/shared/services/registration/urban-planner-registration-state/urban-planner-registration-state.service';
import { DevelopmentSpecialtyDto, LandDevelopmentTypeDto, MunicipalCouncilDto, UrbanPlannerInformationDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupService, MapsService } from 'src/app/services/property-matrixV2/services';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-urban-planner-information',
  templateUrl: './urban-planner-information.component.html',
  styleUrls: ['./urban-planner-information.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class UrbanPlannerInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  urbanPlannerInfoForm: FormGroup;
  municipalCouncils: MunicipalCouncilDto[] = [];
  landDevelopmentTypes: LandDevelopmentTypeDto[] = [];
  developmentSpecialties: DevelopmentSpecialtyDto[] = [];

  isPropertySelected: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _mapsService: MapsService,
    private _lookupService: LookupService,
    private _notificationService: NotificationService,
    private _urbanPlannerRegistrationStateService: UrbanPlannerRegistrationStateService
  ) { }

  ngOnInit(): void {
    this.urbanPlannerInfoForm = this._formBuilder.group({
      graduationYear: [null, [Validators.required, CustomValidators.graduationYearValidator()]],
      graduationInstitution: [null, [Validators.required, Validators.maxLength(250)]],
      yearsOfExperience: [null, [Validators.required, CustomValidators.yearsOfExperienceValidator()]],
      companyName: [null, [Validators.required, Validators.maxLength(250)]],
      isBoundaryCorrect: [true],
      propertyBoundaryConcern: [null, Validators.maxLength(250)],
      propertyAddress: [null, Validators.maxLength(250)],
      propertyDescription: [null, Validators.maxLength(250)],
      companyRegistrationNumber: [null, Validators.maxLength(250)],
      companyVatNumber: [null, Validators.maxLength(250)],
      hasFeasibilityStudyExperience: [false],
      municipalCouncils: new FormArray([], CustomValidators.minSelectedCheckboxes(1)),
      otherMunicipalCouncil: [null, Validators.maxLength(250)],
      landDevelopmentTypes: new FormArray([], CustomValidators.minSelectedCheckboxes(1)),
      otherLandDevelopmentType: [null, Validators.maxLength(250)],
      developmentSpecialties: new FormArray([], CustomValidators.minSelectedCheckboxes(1)),
      otherDevelopmentSpecialty: [null, Validators.maxLength(250)],
      supportName: [null, Validators.maxLength(250)],
      supportSurname: [null, Validators.maxLength(250)],
      supportPosition: [null, Validators.maxLength(250)],
      supportLandLine: [null, [Validators.maxLength(10), CustomValidators.landLineValidator()]],
      supportMobile: [null, [Validators.maxLength(10), CustomValidators.mobileValidator()]],
      supportEmail: [null, [CustomValidators.emailValidator(), Validators.maxLength(250)]],
    });

    this.updateFormValidators();

    this.urbanPlannerInfoForm.get('hasFeasibilityStudyExperience').valueChanges.subscribe(() => {
      this.updateFormValidators();
    });

    this._urbanPlannerRegistrationStateService.formState$.subscribe((formState) => {
      const currentUrbanPlannerInfo = formState.information || {};

      this.urbanPlannerInfoForm.patchValue({
        graduationYear: currentUrbanPlannerInfo.graduationYear,
        graduationInstitution: currentUrbanPlannerInfo.graduationInstitution,
        yearsOfExperience: currentUrbanPlannerInfo.yearsOfExperience,
        companyName: currentUrbanPlannerInfo.companyName,
        isBoundaryCorrect: currentUrbanPlannerInfo.isBoundaryCorrect || true,
        propertyBoundaryConcern: currentUrbanPlannerInfo.propertyBoundaryConcern,
        propertyAddress: currentUrbanPlannerInfo.propertyAddress,
        propertyDescription: currentUrbanPlannerInfo.propertyDescription,
        companyRegistrationNumber: currentUrbanPlannerInfo.companyRegistrationNumber,
        companyVatNumber: currentUrbanPlannerInfo.companyVatNumber,
        hasFeasibilityStudyExperience: currentUrbanPlannerInfo.hasFeasibilityStudyExperience,
        municipalCouncils: currentUrbanPlannerInfo.municipalCouncils,
        otherMunicipalCouncil: currentUrbanPlannerInfo.otherMunicipalCouncil,
        landDevelopmentTypes: currentUrbanPlannerInfo.landDevelopmentTypes,
        otherLandDevelopmentType: currentUrbanPlannerInfo.otherLandDevelopmentType,
        developmentSpecialties: currentUrbanPlannerInfo.developmentSpecialties,
        otherDevelopmentSpecialty: currentUrbanPlannerInfo.otherDevelopmentSpecialty,
        supportName: currentUrbanPlannerInfo.supportName,
        supportSurname: currentUrbanPlannerInfo.supportSurname,
        supportPosition: currentUrbanPlannerInfo.supportPosition,
        supportLandLine: currentUrbanPlannerInfo.supportLandLine,
        supportMobile: currentUrbanPlannerInfo.supportMobile,
        supportEmail: currentUrbanPlannerInfo.supportEmail,
      });
    });

    this.fetchMunicipalCouncils();
    this.fetchLandDevelopmentTypes();
    this.fetchDevelopmentSpecialties();
  }

  handleSelectedPropertyChange(propertyIdentification: any): void {
    this._mapsService.apiV1MapsGetAddressDetailsGet$Response({
      addressId: propertyIdentification.address_id
    }).subscribe({
      next: (response: any) => {
        this.isPropertySelected = true;
        this.urbanPlannerInfoForm.patchValue({
          propertyAddress: response.body.short_address,
          propertyDescription: response.body.full_address,
        });
      }
    });
  }

  updateFormValidators(): void {
    const hasFeasibilityStudyExperience = this.urbanPlannerInfoForm.get('hasFeasibilityStudyExperience').value;

    this.urbanPlannerInfoForm.get('municipalCouncils').setValidators([Validators.required]);
    this.urbanPlannerInfoForm.get('landDevelopmentTypes').setValidators([Validators.required]);

    if (this.isOtherMunicipalCouncilChecked()) {
      this.urbanPlannerInfoForm.get('otherMunicipalCouncil').setValidators([Validators.required]);
    } else {
      this.urbanPlannerInfoForm.get('otherMunicipalCouncil').clearValidators();
    }

    if (this.isOtherLandDevelopmentTypeChecked()) {
      this.urbanPlannerInfoForm.get('otherLandDevelopmentType').setValidators([Validators.required]);
    } else {
      this.urbanPlannerInfoForm.get('otherLandDevelopmentType').clearValidators();
    }

    if (hasFeasibilityStudyExperience) {
      this.urbanPlannerInfoForm.get('developmentSpecialties').setValidators([Validators.required]);
    } else {
      this.urbanPlannerInfoForm.get('developmentSpecialties').clearValidators();
    }

    if (this.isOtherDevelopmentSpecialtyChecked()) {
      this.urbanPlannerInfoForm.get('otherDevelopmentSpecialty').setValidators([Validators.required]);
    } else {
      this.urbanPlannerInfoForm.get('otherDevelopmentSpecialty').clearValidators();
    }
  }

  fetchMunicipalCouncils(): void {
    this._lookupService.apiV1LookupGetMunicipalCouncilsGet().subscribe({
      next: (response: MunicipalCouncilDto[]) => {
        this.municipalCouncils = response.sort((a, b) => a.intValue - b.intValue);
      },
      complete: () => {
        this.addMunicipalCouncilCheckboxes();
      }
    });
  }

  addMunicipalCouncilCheckboxes(): void {
    this.municipalCouncils.forEach(() => {
      const control = new FormControl(false);
      (this.urbanPlannerInfoForm.controls.municipalCouncils as FormArray).push(control);
    });
  }

  isOtherMunicipalCouncilChecked(): boolean {
    const municipalCouncilsArray = this.urbanPlannerInfoForm.controls.municipalCouncils as FormArray;
    const otherMunicipalCouncilIndex = this.municipalCouncils.findIndex(municipalCouncil => municipalCouncil.value === 'Other');
    return otherMunicipalCouncilIndex !== -1 && municipalCouncilsArray.at(otherMunicipalCouncilIndex).value;
  }

  fetchLandDevelopmentTypes(): void {
    this._lookupService.apiV1LookupGetLandDevelopmentTypesGet().subscribe({
      next: (response: LandDevelopmentTypeDto[]) => {
        this.landDevelopmentTypes = response.sort((a, b) => a.intValue - b.intValue);
      },
      complete: () => {
        this.addLandDevelopmentTypeCheckboxes();
      }
    });
  }

  addLandDevelopmentTypeCheckboxes(): void {
    this.landDevelopmentTypes.forEach(() => {
      const control = new FormControl(false);
      (this.urbanPlannerInfoForm.controls.landDevelopmentTypes as FormArray).push(control);
    });
  }

  isOtherLandDevelopmentTypeChecked(): boolean {
    const landDevelopmentTypesArray = this.urbanPlannerInfoForm.controls.landDevelopmentTypes as FormArray;
    const otherLandDevelopmentTypeIndex = this.landDevelopmentTypes.findIndex(landDevelopmentType => landDevelopmentType.value === 'Other');
    return otherLandDevelopmentTypeIndex !== -1 && landDevelopmentTypesArray.at(otherLandDevelopmentTypeIndex).value;
  }

  fetchDevelopmentSpecialties(): void {
    this._lookupService.apiV1LookupGetDevelopmentSpecialtiesGet().subscribe({
      next: (response: DevelopmentSpecialtyDto[]) => {
        this.developmentSpecialties = response.sort((a, b) => a.intValue - b.intValue);
      },
      complete: () => {
        this.addDevelopmentSpecialtyCheckboxes();
      }
    });
  }

  addDevelopmentSpecialtyCheckboxes(): void {
    this.developmentSpecialties.forEach(() => {
      const control = new FormControl(false);
      (this.urbanPlannerInfoForm.controls.developmentSpecialties as FormArray).push(control);
    });
  }

  isOtherDevelopmentSpecialtyChecked(): boolean {
    const developmentSpecialtiesArray = this.urbanPlannerInfoForm.controls.developmentSpecialties as FormArray;
    const otherDevelopmentSpecialtyIndex = this.developmentSpecialties.findIndex(developmentSpecialty => developmentSpecialty.value === 'Other');
    return otherDevelopmentSpecialtyIndex !== -1 && developmentSpecialtiesArray.at(otherDevelopmentSpecialtyIndex).value;
  }

  get isBoundaryCorrect(): boolean {
    return this.urbanPlannerInfoForm.get('isBoundaryCorrect').value;
  }

  get hasFeasibilityStudyExperience(): boolean {
    return this.urbanPlannerInfoForm.get('hasFeasibilityStudyExperience').value;
  }

  onSubmit(): void {
    if (this.urbanPlannerInfoForm.valid) {
      const selectedMunicipalCouncils = this.urbanPlannerInfoForm.value.municipalCouncils
        .map((checked: boolean, i: number) => checked ? this.municipalCouncils[i] : null)
        .filter((municipalCouncil: MunicipalCouncilDto | null) => municipalCouncil !== null);

      const selectedLandDevelopmentTypes = this.urbanPlannerInfoForm.value.landDevelopmentTypes
        .map((checked: boolean, i: number) => checked ? this.landDevelopmentTypes[i] : null)
        .filter((landDevelopmentType: LandDevelopmentTypeDto | null) => landDevelopmentType !== null);

      const selectedDevelopmentSpecialties = this.urbanPlannerInfoForm.value.developmentSpecialties
        .map((checked: boolean, i: number) => checked ? this.developmentSpecialties[i] : null)
        .filter((developmentSpecialty: DevelopmentSpecialtyDto | null) => developmentSpecialty !== null);

      const formValue: UrbanPlannerInformationDto = {
        ...this.urbanPlannerInfoForm.value,
        municipalCouncils: selectedMunicipalCouncils,
        landDevelopmentTypes: selectedLandDevelopmentTypes,
        developmentSpecialties: selectedDevelopmentSpecialties
      };

      this._urbanPlannerRegistrationStateService.updateInformation(formValue);
      this.pageNumberEvent.emit(1);
      this.formSubmittedEvent.emit();
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not submit urban planner information form. Please check the form and try again.');
    }
  }
}
