import { PropertyListingService } from 'src/app/services/property-matrixV2/services';
import { PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingStateService {

  private selectedListingSource = new BehaviorSubject<PropertyListingDto | undefined>(this.loadInitialListing());
  selectedListing$ = this.selectedListingSource.asObservable();

  constructor(private propertyListingService: PropertyListingService) { }

  setSelectedListing(listing: PropertyListingDto): void {
    sessionStorage.setItem('selectedListing', JSON.stringify(listing));
    this.selectedListingSource.next(listing);
  }

  clearSelectedListing(): void {
    sessionStorage.removeItem('selectedListing');
    this.selectedListingSource.next(undefined);
  }

  loadPropertyListingById(listingId: string): void {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({
      listingId: listingId
    }).subscribe({
      next: (listing: PropertyListingDto) => {
        this.setSelectedListing(listing);
      },
      error: (_error: any) => {
        console.error('Could not load listing from API');
      }
    });
  }

  private loadInitialListing(): PropertyListingDto | undefined {
    const listing = sessionStorage.getItem('selectedListing');
    return listing ? JSON.parse(listing) : undefined;
  }
}
