import { GlobalMethods } from 'src/app/common/global-methods';
import { NavigationExtras, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-your-property',
  templateUrl: './sell-your-property.component.html',
  styleUrls: ['./sell-your-property.component.scss']
})
export class SellYourPropertyComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  onProceed(route: string, sellerType: string): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        sellerType: sellerType
      }
    };

    this._router.navigate([route], navigationExtras);
  }
}
