import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-joint-properties-dialog',
  templateUrl: './joint-properties-dialog.component.html',
  styleUrls: ['./joint-properties-dialog.component.scss']
})
export class JointPropertiesDialogComponent implements OnInit {

  constructor(public _dialogRef: MatDialogRef<JointPropertiesDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
