import { UrbanPlannerRegistrationStateService } from 'src/app/shared/services/registration/urban-planner-registration-state/urban-planner-registration-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/services';
import { UrbanPlannerDto } from 'src/app/services/property-matrixV2/models';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-urban-planner',
  templateUrl: './urban-planner.component.html',
  styleUrls: ['./urban-planner.component.scss']
})
export class UrbanPlannerComponent implements OnInit {

  pageNumber: number = 1;
  visitedPages: number[] = [1];
  loading: boolean = false;
  isProgressLockEnabled: boolean = true;

  steps = [
    { label: 'INFORMATION', link: 1 },
    { label: 'EXTENDED NETWORK', link: 2 },
    { label: 'SUBMIT DOCUMENTS', link: 3 },
    { label: 'SIGN CONTRACT', link: 4 }
  ];

  constructor(
    private _snackBar: MatSnackBar,
    private _notificationService: NotificationService,
    private _registrationService: RegistrationService,
    private _urbanPlannerRegistrationStateService: UrbanPlannerRegistrationStateService
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
    window.addEventListener('beforeunload', this.confirmPageExit);
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.confirmPageExit);
  }

  confirmPageExit(event: BeforeUnloadEvent): string {
    event.preventDefault();
    return '';
  }

  unlockNextPage() {
    if (!this.visitedPages.includes(this.pageNumber + 1)) {
      this.visitedPages.push(this.pageNumber + 1);
    }
  }

  selectPage(pageNumber: number) {
    if (this.isProgressLockEnabled && !this.visitedPages.includes(pageNumber)) {
      this._snackBar.open('Please submit the form to proceed to the next step.', 'Close', {
        duration: 3000
      });
      return;
    }
    this.pageNumber = pageNumber;
    GlobalMethods.scrollToTop();
  }

  changePage(page: number) {
    if (this.isProgressLockEnabled && !this.visitedPages.includes(page)) {
      return;
    }
    this.pageNumber = page;
    GlobalMethods.scrollToTop();
  }

  onDocumentSubmitted(): void {
    this.registerUrbanPlanner();
  }

  registerUrbanPlanner(): void {
    this.loading = true;
    const formState: UrbanPlannerDto = this._urbanPlannerRegistrationStateService.getFormState();

    this._registrationService.apiV1RegistrationRegisterUrbanPlannerPost({
      body: formState
    }).subscribe({
      next: () => {
        this.loading = false;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'An error occurred while registering planner.');
        this.loading = false;
      }
    });
  }

  onFormSubmitted() {
    this.unlockNextPage();
    this.changePage(this.pageNumber + 1);
  }
}
