import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ListingLifeCycleState, PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { PropertyListingService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss']
})
export class ListingCardComponent implements OnInit {

  @Input() listing: PropertyListingDto;
  @Input() currentUserIndex: number = 0;
  @Input() users: Array<{ key: number, name: string, role: string, roleInitial: string, roleColor: string }> = [];

  @Output() listingClicked = new EventEmitter<PropertyListingDto>();
  @Output() listingCardSelected = new EventEmitter<string>();

  selected: boolean = false;
  addressTitle: string | null = null;
  completeAddress: string | null = null;
  surveyorGeneralKey: string | null = null;
  placeholderImageUrl: string = '../../../../../assets/images/placeholders/property-matrix-placeholder.png';

  constructor(
    private notificationService: NotificationService,
    private propertyListingService: PropertyListingService
  ) { }

  ngOnInit(): void {
    this.addressTitle = this.listing.addressTitle === "Erf , " ? "Address Title Unavailable" : this.listing.addressTitle;
    this.completeAddress = this.listing.completeAddress || "No additional address details available";
    this.surveyorGeneralKey = this.listing.surveyorGeneralKey || "S.G. Code Unavailable";
  }

  onError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.placeholderImageUrl;
  }

  selectListingForMultiple() {
    this.selected = !this.selected;
    this.listingCardSelected.emit(this.listing.id);
  }

  archiveListing() {
    this.listing.lifeCycleState = ListingLifeCycleState.Archived;
  }

  unarchiveListing() {
    this.listing.lifeCycleState = ListingLifeCycleState.Assigned;
  }

  toggleSelectListing() {
    this.selected = !this.selected;
    this.listingCardSelected.emit(this.listing.id);
  }

  getArchiveButtonText() {
    return this.listing.lifeCycleState === ListingLifeCycleState.Archived ? 'Unarchive Listing' : 'Archive Listing';
  }

  getSelectButtonText() {
    return this.selected ? 'Deselect Listing' : 'Select Listing';
  }

  goToListingOverview(): void {
    this.listingClicked.emit(this.listing);
  }

  getCurrentUser(): any {
    let adjustedIndex = (this.currentUserIndex - 1) % this.users.length;
    return this.users[adjustedIndex];
  }

  deleteListing(): void {
    this.notificationService.showConfirmMessage('Confirm Deletion', 'Are you sure you want to delete this listing?').then((confirmed) => {
      if (confirmed) {
        this.propertyListingService.apiV1PropertyListingDeletePropertyListingDelete({
          listingId: this.listing.id
        }).subscribe({
          next: () => {
            this.notificationService.showSuccessMessage('Success', 'Listing deleted successfully.');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Could not delete listing.');
          }
        });
      }
    });
  }
}
