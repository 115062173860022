import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-introduction-step',
  templateUrl: './introduction-step.component.html',
  styleUrls: ['./introduction-step.component.scss']
})
export class IntroductionStepComponent implements OnInit {

  userType: string | null = null;
  videoLoaded: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    this.loadVideo();

    GlobalMethods.scrollToTop();
  }

  private loadVideo(): void {
    const videoElement: HTMLVideoElement = document.createElement('video');
    videoElement.src = 'assets/videos/stock-video.mp4';

    videoElement.onloadeddata = () => {
      this.videoLoaded = true;
    };

    videoElement.onerror = () => {
      this.videoLoaded = false;
    };
  }

  proceed() {
    this.route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };
      this.router.navigate(['registration-step'], navigationExtras);
    });
  }
}
