<main class="main-container" id="main" [class.page-background]="pageNumber === 4">
    <div class="content">
        <div class="container">
            <app-progress-tracker [steps]="steps" [currentStep]="pageNumber - 1" (stepClick)="selectPage($event)">
            </app-progress-tracker>
            <section class="section-container">
                <div [hidden]="pageNumber != 1">
                    <app-estate-agent-personal-information (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()">
                    </app-estate-agent-personal-information>
                </div>
                <div [hidden]="pageNumber != 2">
                    <app-estate-agent-company-information (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()">
                    </app-estate-agent-company-information>
                </div>
                <div [hidden]="pageNumber != 3">
                    <app-estate-agent-submit-documents (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()">
                    </app-estate-agent-submit-documents>
                </div>
                <div [hidden]="pageNumber != 4">
                    <app-estate-agent-sign-contract (contractSigned)="onContractSigned()">
                    </app-estate-agent-sign-contract>
                </div>
            </section>
        </div>
    </div>
</main>