import { PlanningReportService, ReportGeneratorService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting-service/reporting.service';
import { ExportReportDto } from 'src/app/services/property-matrixV2/models';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ExportReportComponent implements OnInit, OnChanges {

  @Input() exportReportData: ExportReportDto;
  @Input() listingId: string;
  @Input() multipleListingId: string;
  @Input() isMultiple: boolean;

  @Output() tabNumberEvent = new EventEmitter<number>();

  loading: boolean = false;

  exportReportDto: ExportReportDto;

  sellerCompanyLogoFile: any;
  sellerCompanyLogoFileId: string = null;
  sellerCompanyLogoFileUrl: string = null;
  sellerCompanyHideButtons: boolean = false;

  plannerCompanyLogoFile: any;
  plannerCompanyLogoFileId: string = null;
  plannerCompanyLogoFileUrl: string = null;
  plannerCompanyHideButtons: boolean = false;

  seniorPlannerSignatureFile: any;
  seniorPlannerSignatureFileId: string = null;
  seniorPlannerSignatureFileUrl: string = null;
  seniorPlannerSignatureHideButtons: boolean = false;

  sections = [
    { id: 1, value: 'Export Report', checked: false }
  ];

  constructor(
    public dialog: MatDialog,
    private keycloakService: KeycloakService,
    private reportingService: ReportingService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService,
    private reportGeneratorService: ReportGeneratorService,
  ) { }

  ngOnInit(): void {
    this.getUserId();
    this.loadAllExportReportData();
    this.loadSellerCompanyLogoFile();
    this.loadPlannerCompanyLogoFile();
    this.loadSeniorPlannerSignatureFile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exportReportData) {
      this.loadAllExportReportData();
    }
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  loadAllExportReportData() {
    this.exportReportDto = this.exportReportData || {};
    this.sellerCompanyLogoFileId = this.exportReportData?.sellerCompanyLogoFileId;
    this.plannerCompanyLogoFileId = this.exportReportData?.plannerCompanyLogoFileId;
    this.seniorPlannerSignatureFileId = this.exportReportData?.seniorPlannerSignatureFileId;
  }

  saveExportReportDetails() {
    this.loading = true;
    this.planningReportService.apiV1PlanningReportAddOrUpdateExportReportPost({
      propertyListingId: this.listingId,
      body: this.exportReportData
    }).subscribe({
      next: () => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Successfully saved export details.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not save export details.');
      }
    });
  }

  exportLevelOnePlanningReport() {
    if (!this.isMultiple) {
      this.exportLevelOneSinglePlanningReport();
    } else {
      this.exportLevelOneMultiplePlanningReport();
    }
  }

  exportLevelOneSinglePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelOneSingleReportGet({
      listingId: this.listingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  exportLevelOneMultiplePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet({
      multipleListingId: this.multipleListingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  exportLevelTwoPlanningReport() {
    if (!this.isMultiple) {
      this.exportLevelTwoSinglePlanningReport();
    } else {
      this.exportLevelTwoMultiplePlanningReport();
    }
  }

  exportLevelTwoSinglePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelTwoSingleReportGet({
      listingId: this.listingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  exportLevelTwoMultiplePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet({
      multipleListingId: this.multipleListingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  async setSellerCompanyLogoFile(event: { target: { files: any[]; }; }) {
    try {
      this.sellerCompanyLogoFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.sellerCompanyLogoFileId = this.sellerCompanyLogoFileId;
      this.sellerCompanyLogoFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.sellerCompanyLogoFileUrl = event.target.result;
        this.sellerCompanyHideButtons = true;
      }

      reader.readAsDataURL(this.sellerCompanyLogoFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replaceSellerCompanyLogoFile() {
    this.sellerCompanyLogoFileUrl = null;
    this.sellerCompanyHideButtons = false;
  }

  async loadSellerCompanyLogoFile() {
    if (this.sellerCompanyLogoFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.sellerCompanyLogoFileId);
        this.sellerCompanyLogoFileUrl = fileUrl;
        this.sellerCompanyHideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async setPlannerCompanyLogoFile(event: { target: { files: any[]; }; }) {
    try {
      this.plannerCompanyLogoFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.plannerCompanyLogoFileId = this.plannerCompanyLogoFileId;
      this.plannerCompanyLogoFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.plannerCompanyLogoFileUrl = event.target.result;
        this.plannerCompanyHideButtons = true;
      }

      reader.readAsDataURL(this.plannerCompanyLogoFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replacePlannerCompanyLogoFile() {
    this.plannerCompanyLogoFileUrl = null;
    this.plannerCompanyHideButtons = false;
  }

  async loadPlannerCompanyLogoFile() {
    if (this.plannerCompanyLogoFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.plannerCompanyLogoFileId);
        this.plannerCompanyLogoFileUrl = fileUrl;
        this.plannerCompanyHideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async setSeniorPlannerSignatureFile(event: { target: { files: any[]; }; }) {
    try {
      this.seniorPlannerSignatureFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.seniorPlannerSignatureFileId = this.seniorPlannerSignatureFileId;
      this.seniorPlannerSignatureFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.seniorPlannerSignatureFileUrl = event.target.result;
        this.seniorPlannerSignatureHideButtons = true;
      }

      reader.readAsDataURL(this.seniorPlannerSignatureFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replaceSeniorPlannerSignatureFile() {
    this.seniorPlannerSignatureFileUrl = null;
    this.seniorPlannerSignatureHideButtons = false;
  }

  async loadSeniorPlannerSignatureFile() {
    if (this.seniorPlannerSignatureFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.seniorPlannerSignatureFileId);
        this.seniorPlannerSignatureFileUrl = fileUrl;
        this.seniorPlannerSignatureHideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  private getUserId(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed?.sub;
  }

  openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }

  goToAddendumThree() {
    this.tabNumberEvent.emit(4);
  }
}
