<main class="dialog-container">
    <button class="close-button" (click)="closeDialog()">
        <mat-icon class="close-button-icon">close</mat-icon>
    </button>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <h4 class="dialog-heading">
                Disclaimer
            </h4>
        </div>
    </div>
    <div mat-dialog-content>
        <p class="list-items paragraph light">
            In the event that the seller decide to appoint their own conveyancers, please be aware that Property Matrix
            cannot guarantee the quality of conveyancing work or the estimated turnaround times. In the absence of an
            established relationship, conveyancers may not have knowledge of our platform, workflow, and network
            partners.
        </p>
        <button class="button-light heading-3-light" (click)="closeDialog()">
            GOT IT
        </button>
    </div>
</main>