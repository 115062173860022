<main class="dialog-container">
    <button class="close-button" (click)="closeDialog()">
        <mat-icon class="close-button-icon">close</mat-icon>
    </button>

    <div class="row paragraph mt-3">
        <h4 class="dialog-heading">Add Juristic Person (Entity)</h4>
    </div>

    <div mat-dialog-content>
        <form [formGroup]="juristicPersonForm" (ngSubmit)="onSubmit()">

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="fullName">Full name of juristic person (entity):</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="fullName" formControlName="fullName" type="text"
                        maxlength="250">
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="registrationNumberOfEntity">Registration number of the entity:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="registrationNumberOfEntity"
                        formControlName="registrationNumberOfEntity" type="text" maxlength="250">
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="vatNumber">If applicable, VAT number of the entity:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="vatNumber" formControlName="vatNumber" type="text"
                        maxlength="250">
                </div>
            </div>

            <div class="row paragraph mt-5">
                <label class="bold">Details of the legal representative:</label>
            </div>

            <div formGroupName="legalRepresentative">
                <div class="row paragraph mt-4">
                    <div class="col-3">
                        <label for="legalRepresentativeTitle">Title:</label>
                    </div>
                    <div class="col-9">
                        <input class="default-input" id="legalRepresentativeTitle" formControlName="title" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeName">Name:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativeName" formControlName="fullNames"
                            type="text" maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeSurname">Surname:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativeSurname" formControlName="surname"
                            type="text" maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeWorkPhoneNumber">Work phone:</label>
                    </div>
                    <div class="col-9">
                        <input class="default-input" id="legalRepresentativeWorkPhoneNumber"
                            formControlName="workPhoneNumber" type="text" [ngClass]="{
                                'invalid-input':
                                    juristicPersonForm.get('legalRepresentative.workPhoneNumber').invalid &&
                                    juristicPersonForm.get('legalRepresentative.workPhoneNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative.workPhoneNumber').invalid &&
                                juristicPersonForm.get('legalRepresentative.workPhoneNumber').touched">
                            * Work phone number must contain exactly 10 digits.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeMobilePhoneNumber">Mobile:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativeMobilePhoneNumber"
                            formControlName="mobilePhoneNumber" type="text" [ngClass]="{
                                'invalid-input':
                                    juristicPersonForm.get('legalRepresentative.mobilePhoneNumber').invalid &&
                                    juristicPersonForm.get('legalRepresentative.mobilePhoneNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative.mobilePhoneNumber').invalid &&
                                juristicPersonForm.get('legalRepresentative.mobilePhoneNumber').touched">
                            * Mobile number must contain exactly 10 digits.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeEmailAddress">Email:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativeEmailAddress"
                            formControlName="emailAddress" type="text" [ngClass]="{
                                'invalid-input':
                                    juristicPersonForm.get('legalRepresentative.emailAddress').invalid &&
                                    juristicPersonForm.get('legalRepresentative.emailAddress').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative.emailAddress').invalid &&
                                juristicPersonForm.get('legalRepresentative.emailAddress').touched">
                            * Invalid email address.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeConfirmEmail">Confirm email:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativeConfirmEmail"
                            formControlName="confirmEmail" type="text" [ngClass]="{
                                'invalid-input':
                                    juristicPersonForm.get('legalRepresentative.confirmEmail').invalid &&
                                    juristicPersonForm.get('legalRepresentative.confirmEmail').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative.confirmEmail').invalid &&
                                juristicPersonForm.get('legalRepresentative.confirmEmail').touched">
                            * Invalid email address.
                        </small>
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative').hasError('emailMismatch') &&
                                juristicPersonForm.get('legalRepresentative.confirmEmail').touched">
                            Email addresses must match.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-5">
                    <label class="bold mb-2">
                        <span class="burnt-red">|</span>
                        Is the legal representative a resident of the Republic of South Africa?
                    </label>
                    <mat-radio-group formControlName="isSouthAfricanResident">
                        <mat-radio-button class="mt-1" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div *ngIf="isLegalRepresentativeSouthAfricanResident === true" class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativeIdentityNumber">Identity number:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativeIdentityNumber"
                            formControlName="identityNumber" type="text" [ngClass]="{
                                'invalid-input':
                                    juristicPersonForm.get('legalRepresentative.identityNumber').invalid &&
                                    juristicPersonForm.get('legalRepresentative.identityNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative.identityNumber').invalid &&
                                juristicPersonForm.get('legalRepresentative.identityNumber').touched">
                            * Identity number must contain exactly 13 digits.
                        </small>
                    </div>
                </div>

                <div *ngIf="isLegalRepresentativeSouthAfricanResident === false" class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="legalRepresentativePassportNumber">Passport number:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="legalRepresentativePassportNumber"
                            formControlName="passportNumber" type="text" [ngClass]="{
                                'invalid-input':
                                    juristicPersonForm.get('legalRepresentative.passportNumber').invalid &&
                                    juristicPersonForm.get('legalRepresentative.passportNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                juristicPersonForm.get('legalRepresentative.passportNumber').invalid &&
                                juristicPersonForm.get('legalRepresentative.passportNumber').touched">
                            * Passport number must start with a capital letter and be exactly 9 characters long.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-5">
                    <div class="col-12">
                        <u class="bold">Please confirm</u>
                    </div>
                    <div class="col-12 custom-checkbox-container">
                        <input type="checkbox" id="legalRepresentativeConfirmedConsent"
                            formControlName="confirmedConsent" class="custom-checkbox">
                        <label for="legalRepresentativeConfirmedConsent" class="custom-checkbox-label">
                            I have consent from the legal representative of the entity to fill in this form and to
                            provide Property Matrix with his/her details.
                        </label>
                    </div>
                </div>
            </div>

            <div class="row paragraph mt-5">
                <div class="text-center">
                    <button class="button" type="submit" [disabled]="juristicPersonForm.invalid">Submit</button>
                </div>
            </div>
        </form>
    </div>
</main>