<main class="dialog-container">
    <button class="close-button" (click)="closeDialog()">
        <mat-icon class="close-button-icon">close</mat-icon>
    </button>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <h4 class="dialog-heading">
                Benefits of using the Property Matrix Legal Team
            </h4>
        </div>
    </div>
    <div mat-dialog-content>
        <ul class="list-items paragraph light">
            <li>
                Our conveyancers have a nationwide presence and extensive expertise in property development, spatial
                planning, and land use management. Their specialist services are backed by over a century of collective
                conveyancing experience.
            </li>
            <li>
                Our team is trusted by all major financial institutions and investment houses and on the panel of most
                of the major South African commercial banks.
            </li>
            <li>
                Our in-house services boast foreseeable faster timelines in attending to initial work due to our
                longstanding collaboration: our team is familiar with the Property Matrix platform, workflow, and
                network partners (i.e. network planners/network agents/network valuers).
            </li>
            <li>
                We attend to the initial due diligence work for your property, including drafting a basic conveyancing
                report and will therefore be well-versed regarding the legal aspects and potential of your property.
            </li>
            <li>
                Our panel of conveyancers has served numerous clients, including banks, developers, city councils, and
                Brokers/Agents. Their expertise includes handling various types of developments (residential, industrial
                and commercial), registration of mega sectional title schemes, and providing counsel on property
                development structures.
            </li>
            <li>
                If appointed, they possess the expertise to take care of drafting conveyancing certificates and
                negotiations with the council, drafting commercial property sale agreements, and, if required, represent
                clients in hearings before the Municipal Planning Tribunal (MPT) in line with SPLUMA and applicable
                planning legislation/by-laws.
            </li>
        </ul>
        <button class="button-light heading-3-light" (click)="closeDialog()">
            GOT IT
        </button>
    </div>
</main>