import { UrbanPlannerRegistrationStateService } from 'src/app/shared/services/registration/urban-planner-registration-state/urban-planner-registration-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { UrbanPlannerExtendedNetworkDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AddEditExtendedProfessionalDialogComponent } from './add-edit-extended-professional-dialog/add-edit-extended-professional-dialog.component';

@Component({
  selector: 'app-urban-planner-extended-network',
  templateUrl: './urban-planner-extended-network.component.html',
  styleUrls: ['./urban-planner-extended-network.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class UrbanPlannerExtendedNetworkComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  @ViewChild(MatTable) professionalTable: MatTable<any>;

  extendedNetworksForm: FormGroup;
  extendedNetworks: UrbanPlannerExtendedNetworkDto[] = [];
  displayedColumns: string[] = ['number', 'professional', 'profession', 'edit', 'delete'];

  dataSource = new MatTableDataSource(this.extendedNetworks);

  constructor(
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _notificationService: NotificationService,
    private _urbanPlannerRegistrationStateService: UrbanPlannerRegistrationStateService
  ) { }

  ngOnInit(): void {
    this.extendedNetworksForm = this._formBuilder.group({});

    this._urbanPlannerRegistrationStateService.formState$.subscribe((formState) => {
      this.extendedNetworks = formState.extendedNetworks || [];
      this.dataSource.data = this.extendedNetworks;
    });
  }

  addProfessional(): void {
    const dialogRef = this._dialog.open(AddEditExtendedProfessionalDialogComponent, {
      width: '60vw',
      data: { index: this.extendedNetworks.length + 1 }
    });

    dialogRef.afterClosed().subscribe((result: UrbanPlannerExtendedNetworkDto) => {
      if (result) {
        this.extendedNetworks.push(result);
        this.updateExtendedNetworkState();
      }
    });
  }

  editProfessionalItem(professional: UrbanPlannerExtendedNetworkDto): void {
    const dialogRef = this._dialog.open(AddEditExtendedProfessionalDialogComponent, {
      width: '60vw',
      data: professional
    });

    dialogRef.afterClosed().subscribe((result: UrbanPlannerExtendedNetworkDto) => {
      if (result) {
        const index = this.extendedNetworks.findIndex(p => p.index === professional.index);
        if (index >= 0) {
          this.extendedNetworks[index] = result;
          this.updateExtendedNetworkState();
        }
      }
    });
  }

  deleteProfessionalItem(professional: UrbanPlannerExtendedNetworkDto): void {
    this._notificationService.showConfirmMessage(
      'Confirm Deletion',
      `Are you sure you want to delete ${professional.name}?`,
      'Yes',
      'No'
    ).then((confirmed) => {
      if (confirmed) {
        const index = this.extendedNetworks.findIndex(p => p.index === professional.index);
        if (index >= 0) {
          this.extendedNetworks.splice(index, 1);
          this.updateExtendedNetworkState();
        }
      }
    });
  }

  onSubmit(): void {
    if (this.extendedNetworksForm.valid) {
      this._urbanPlannerRegistrationStateService.updateExtendedNetwork(this.extendedNetworks);
      this.pageNumberEvent.emit(2);
      this.formSubmittedEvent.emit();
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not submit extended network form. Please check the form and try again.');
    }
  }

  private updateExtendedNetworkState(): void {
    this.dataSource.data = this.extendedNetworks;
    this._urbanPlannerRegistrationStateService.updateExtendedNetwork(this.extendedNetworks);
    this.professionalTable.renderRows();
  }
}
