import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collaboration-agreement',
  templateUrl: './collaboration-agreement.component.html',
  styleUrls: ['./collaboration-agreement.component.scss']
})
export class CollaborationAgreementComponent implements OnInit {

  userType: string | null = null;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    GlobalMethods.scrollToTop();
  }

  proceed() {
    this._route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };

      this._router.navigate(['/register'], navigationExtras);
    });
  }
}
