import { JuristicPersonDetailDto, PersonalDetailDto, PropertyOwnershipTypeDto } from 'src/app/services/property-matrixV2/models';
import { PropertyListingStateService } from 'src/app/shared/services/sell-your-property/property-listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';

import { AddEditJuristicPersonComponent } from './add-edit-juristic-person/add-edit-juristic-person.component';
import { AddEditNaturalPersonComponent } from './add-edit-natural-person/add-edit-natural-person.component';

@Component({
  selector: 'app-listing-ownership-details',
  templateUrl: './listing-ownership-details.component.html',
  styleUrls: ['./listing-ownership-details.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class ListingOwnershipDetailsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  @ViewChild(MatTable) naturalPersonTable: MatTable<PersonalDetailDto>;
  @ViewChild(MatTable) juristicPersonTable: MatTable<JuristicPersonDetailDto>;

  ownershipDetailsForm: FormGroup;

  naturalPersons: PersonalDetailDto[] = [];
  juristicPersons: JuristicPersonDetailDto[] = [];
  propertyOwnershipTypes: PropertyOwnershipTypeDto[] = [];

  selectedPropertyOwnershipType: PropertyOwnershipTypeDto;

  naturalPersonTableDataSource = new MatTableDataSource(this.naturalPersons);
  juristicPersonTableDataSource = new MatTableDataSource(this.juristicPersons);

  displayedColumns: string[] = ['number', 'name', 'entity', 'edit', 'delete'];

  constructor(
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _lookupService: LookupService,
    private _notificationService: NotificationService,
    private _propertyListingStateService: PropertyListingStateService
  ) { }

  ngOnInit(): void {
    this.fetchPropertyOwnershipTypes();

    this.ownershipDetailsForm = this._formBuilder.group({
      propertyOwnershipType: [null, Validators.required],
      naturalPersons: [null],
      juristicPersons: [null]
    });

    this._propertyListingStateService.formState$.subscribe((formState) => {
      this.selectedPropertyOwnershipType = formState?.ownershipDetail?.propertyOwnershipType;
      this.naturalPersons = formState?.ownershipDetail?.ownershipPersonalDetail || [];
      this.juristicPersons = formState?.ownershipDetail?.juristicPersonDetail || [];
      this.naturalPersonTableDataSource.data = this.naturalPersons;
      this.juristicPersonTableDataSource.data = this.juristicPersons;
    });
  }

  onOwnershipTypeChange(event: MatRadioChange): void {
    this.selectedPropertyOwnershipType = event.value;
    this._propertyListingStateService.updatePropertyOwnershipType(this.selectedPropertyOwnershipType);
  }

  addNaturalPerson(): void {
    const dialogRef = this._dialog.open(AddEditNaturalPersonComponent, {
      width: '60vw',
      data: { index: this.naturalPersons.length + 1 }
    });

    dialogRef.afterClosed().subscribe((result: PersonalDetailDto) => {
      if (result) {
        this.naturalPersons.push(result);
        this.updateNaturalPersonDetailsState();
      }
    });
  }

  editNaturalPerson(naturalPerson: PersonalDetailDto): void {
    const dialogRef = this._dialog.open(AddEditNaturalPersonComponent, {
      width: '60vw',
      data: naturalPerson
    });

    dialogRef.afterClosed().subscribe((result: PersonalDetailDto) => {
      if (result) {
        const index = this.naturalPersons.findIndex(p => p.index === naturalPerson?.index);
        if (index >= 0) {
          this.naturalPersons[index] = result;
          this.updateNaturalPersonDetailsState();
        }
      }
    });
  }

  deleteNaturalPerson(naturalPerson: PersonalDetailDto): void {
    this._notificationService.showConfirmMessage(
      'Confirm Deletion',
      `Are you sure you want to delete ${naturalPerson?.fullNames}?`,
      'Yes',
      'No'
    ).then((confirmed) => {
      if (confirmed) {
        const index = this.naturalPersons.findIndex(p => p.index === naturalPerson?.index);
        if (index >= 0) {
          this.naturalPersons.splice(index, 1);
          this.updateNaturalPersonDetailsState();
        }
      }
    });
  }

  addJuristicPerson(): void {
    const dialogRef = this._dialog.open(AddEditJuristicPersonComponent, {
      width: '60vw',
      data: { index: this.juristicPersons.length + 1 }
    });

    dialogRef.afterClosed().subscribe((result: JuristicPersonDetailDto) => {
      if (result) {
        this.juristicPersons.push(result);
        this.updateJuristicPersonDetailsState();
      }
    });
  }

  editJuristicPerson(juristicPerson: JuristicPersonDetailDto): void {
    const dialogRef = this._dialog.open(AddEditJuristicPersonComponent, {
      width: '60vw',
      data: juristicPerson
    });

    dialogRef.afterClosed().subscribe((result: JuristicPersonDetailDto) => {
      if (result) {
        const index = this.juristicPersons.findIndex(p => p.index === juristicPerson?.index);
        if (index >= 0) {
          this.juristicPersons[index] = result;
          this.updateJuristicPersonDetailsState();
        }
      }
    });
  }

  deleteJuristicPerson(juristicPerson: JuristicPersonDetailDto): void {
    this._notificationService.showConfirmMessage(
      'Confirm Deletion',
      `Are you sure you want to delete ${juristicPerson?.fullName}?`,
      'Yes',
      'No'
    ).then((confirmed) => {
      if (confirmed) {
        const index = this.juristicPersons.findIndex(p => p.index === juristicPerson?.index);
        if (index >= 0) {
          this.juristicPersons.splice(index, 1);
          this.updateJuristicPersonDetailsState();
        }
      }
    });
  }

  private fetchPropertyOwnershipTypes(): void {
    this._lookupService.apiV1LookupGetPropertyOwnershipTypeGet().subscribe({
      next: (response: PropertyOwnershipTypeDto[]) => {
        this.propertyOwnershipTypes = response.sort((a, b) => a.intValue - b.intValue);
      }
    });
  }

  private updateNaturalPersonDetailsState(): void {
    this.naturalPersonTableDataSource.data = this.naturalPersons;
    this._propertyListingStateService.updateNaturalPersonDetails(this.naturalPersons);
    this.naturalPersonTable.renderRows();
  }

  private updateJuristicPersonDetailsState(): void {
    this.juristicPersonTableDataSource.data = this.juristicPersons;
    this.juristicPersonTable.renderRows();
    this._propertyListingStateService.updateJuristicPersonDetails(this.juristicPersons);
  }

  onSubmit(): void {
    if (this.ownershipDetailsForm.valid) {
      this._propertyListingStateService.updateNaturalPersonDetails(this.naturalPersons);
      this._propertyListingStateService.updateJuristicPersonDetails(this.juristicPersons);
      this.pageNumberEvent.emit(1);
      this.formSubmittedEvent.emit();
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not submit ownership details form. Please check the form and try again.');
    }
  }
}
