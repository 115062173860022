import { PropertyListingStateService } from 'src/app/shared/services/sell-your-property/property-listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';

import { DisclaimerDialogComponent } from './disclaimer-dialog/disclaimer-dialog.component';
import { BenefitsDialogComponent } from './benefits-dialog/benefits-dialog.component';

@Component({
  selector: 'app-listing-contract-details',
  templateUrl: './listing-contract-details.component.html',
  styleUrls: ['./listing-contract-details.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class ListingContractDetailsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  contractDetailsForm: FormGroup;

  constructor(
    public _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _notificationService: NotificationService,
    private _propertyListingStateService: PropertyListingStateService
  ) { }

  ngOnInit(): void {
    this.contractDetailsForm = this._formBuilder.group({
      usePropertyMatrixLegalTeam: [null, Validators.required],
      conveyancerDetail: this._formBuilder.group({
        companyName: [null],
        contactPerson: [null],
        emailAddress: [null],
        mobileNumber: [null],
        officeNumber: [null]
      }),
      connectedToUrbanPlanner: [null, Validators.required],
      urbanPlannerDetail: this._formBuilder.group({
        name: [null],
        surname: [null],
        company: [null],
        city: [null],
        email: [null],
        phone: [null]
      })
    });

    this.updateConveyancerValidation();
    this.updateUrbanPlannerValidation();
  }

  private updateConveyancerValidation(): void {
    const conveyancerDetailGroup = this.contractDetailsForm.get('conveyancerDetail') as FormGroup;

    if (this.usePropertyMatrixLegalTeam === false) {
      conveyancerDetailGroup.get('companyName').setValidators([Validators.required, Validators.maxLength(250)]);
      conveyancerDetailGroup.get('contactPerson').setValidators([Validators.required, Validators.maxLength(250)]);
      conveyancerDetailGroup.get('emailAddress').setValidators([Validators.required, Validators.email]);
      conveyancerDetailGroup.get('mobileNumber').setValidators([Validators.required, CustomValidators.mobileValidator()]);
      conveyancerDetailGroup.get('officeNumber').setValidators([Validators.required, CustomValidators.mobileValidator()]);
    } else if (this.usePropertyMatrixLegalTeam === true) {
      conveyancerDetailGroup.get('companyName').clearValidators();
      conveyancerDetailGroup.get('contactPerson').clearValidators();
      conveyancerDetailGroup.get('emailAddress').clearValidators();
      conveyancerDetailGroup.get('mobileNumber').clearValidators();
      conveyancerDetailGroup.get('officeNumber').clearValidators();
    }

    conveyancerDetailGroup.get('companyName').updateValueAndValidity();
    conveyancerDetailGroup.get('contactPerson').updateValueAndValidity();
    conveyancerDetailGroup.get('emailAddress').updateValueAndValidity();
    conveyancerDetailGroup.get('mobileNumber').updateValueAndValidity();
    conveyancerDetailGroup.get('officeNumber').updateValueAndValidity();
  }

  updateUrbanPlannerValidation(): void {
    const urbanPlannerDetailGroup = this.contractDetailsForm.get('urbanPlannerDetail') as FormGroup;

    if (this.connectedToUrbanPlanner === true) {
      urbanPlannerDetailGroup.get('name').setValidators([Validators.required, Validators.maxLength(250)]);
      urbanPlannerDetailGroup.get('surname').setValidators([Validators.required, Validators.maxLength(250)]);
      urbanPlannerDetailGroup.get('company').setValidators([Validators.maxLength(250)]);
      urbanPlannerDetailGroup.get('city').setValidators([Validators.required, Validators.maxLength(250)]);
      urbanPlannerDetailGroup.get('email').setValidators([Validators.required, Validators.email]);
      urbanPlannerDetailGroup.get('phone').setValidators([Validators.required, CustomValidators.mobileValidator()]);
    } else if (this.connectedToUrbanPlanner === false) {
      urbanPlannerDetailGroup.get('name').clearValidators();
      urbanPlannerDetailGroup.get('surname').clearValidators();
      urbanPlannerDetailGroup.get('company').clearValidators();
      urbanPlannerDetailGroup.get('city').clearValidators();
      urbanPlannerDetailGroup.get('email').clearValidators();
      urbanPlannerDetailGroup.get('phone').clearValidators();
    }

    urbanPlannerDetailGroup.get('name').updateValueAndValidity();
    urbanPlannerDetailGroup.get('surname').updateValueAndValidity();
    urbanPlannerDetailGroup.get('company').updateValueAndValidity();
    urbanPlannerDetailGroup.get('city').updateValueAndValidity();
    urbanPlannerDetailGroup.get('email').updateValueAndValidity();
    urbanPlannerDetailGroup.get('phone').updateValueAndValidity();
  }

  openBenefitsDialog(event: Event): void {
    event.preventDefault();
    this._dialog.open(BenefitsDialogComponent, {
      width: '60vw'
    });
  }

  onConveyancerRadioChange(event: MatRadioChange): void {
    this.updateConveyancerValidation();
    if (event.value === false) {
      this.openDisclaimerDialog();
    }
  }

  private openDisclaimerDialog(): void {
    this._dialog.open(DisclaimerDialogComponent, {
      width: '60vw'
    });
  }

  get usePropertyMatrixLegalTeam(): boolean {
    return this.contractDetailsForm.get('usePropertyMatrixLegalTeam').value;
  }

  get connectedToUrbanPlanner(): boolean {
    return this.contractDetailsForm.get('connectedToUrbanPlanner').value;
  }

  onSubmit(): void {
    if (this.contractDetailsForm.valid) {
      this._propertyListingStateService.updateContractDetails(this.contractDetailsForm.value);
      this.pageNumberEvent.emit(3);
      this.formSubmittedEvent.emit();
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not submit contract details form. Please check the form and try again.');
    }
  }
}
