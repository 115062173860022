import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-additional-information-dialog',
  templateUrl: './additional-information-dialog.component.html',
  styleUrls: ['./additional-information-dialog.component.scss']
})
export class AdditionalInformationDialogComponent implements OnInit {

  constructor(public _dialogRef: MatDialogRef<AdditionalInformationDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
