<main class="dialog-container">
    <button class="close-button" (click)="closeDialog()">
        <mat-icon class="close-button-icon">close</mat-icon>
    </button>
    <form [formGroup]="professionalForm" (ngSubmit)="onSubmit()">
        <div class="row paragraph mt-3">
            <div class="col-12">
                <h4 class="dialog-heading">
                    Add Extended Professional
                </h4>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="name">Name:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="name" formControlName="name" type="text" maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="surname">Surname:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="surname" formControlName="surname" type="text"
                        maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="profession">Profession:</label>
                </div>
                <div class="col-9">
                    <select required class="default-select" id="profession" formControlName="profession" type="text">
                        <option *ngFor="let profession of professions" [ngValue]="profession">
                            {{ profession.value }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="companyName">Company name:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="companyName" formControlName="companyName" type="text"
                        maxlength="250">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="mobile">Mobile:</label>
                </div>
                <div class="col-9">
                    <input class="default-input" id="mobile" formControlName="mobile" type="text" [ngClass]="{'invalid-input':
                        professionalForm.get('mobile').invalid &&
                        professionalForm.get('mobile').touched}">
                    <small class="burnt-red" *ngIf="
                        professionalForm.get('mobile').invalid &&
                        professionalForm.get('mobile').touched">
                        * Mobile number must contain exactly 10 digits.
                    </small>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="email">Email:</label>
                </div>
                <div class="col-9">
                    <input class="default-input" id="email" formControlName="email" type="text" [ngClass]="{'invalid-input':
                        professionalForm.get('email').invalid &&
                        professionalForm.get('email').touched}">
                    <small class="burnt-red" *ngIf="
                        professionalForm.get('email').invalid &&
                        professionalForm.get('email').touched">
                        * Invalid email address.
                    </small>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="website">Link to their website:</label>
                </div>
                <div class="col-9">
                    <input class="default-input" id="website" formControlName="website" type="text">
                </div>
            </div>
            <div class="row paragraph mt-5">
                <div class="text-center">
                    <button class="button" type="submit" [disabled]="professionalForm.invalid">SUBMIT</button>
                </div>
            </div>
        </div>
    </form>
</main>