import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  /* Region: Urban Planner Registration Validators */

  /**
   * Validator to check if the graduation year is between 1900 and 2099.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static graduationYearValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = control.value >= 1900 && control.value <= 2099;
      return isValid ? null : { 'invalidGraduationYear': { value: control.value } };
    };
  }

  /**
   * Validator to check if the years of experience is between 0 and 100.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static yearsOfExperienceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = control.value >= 0 && control.value <= 100;
      return isValid ? null : { 'invalidYearsOfExperience': { value: control.value } };
    };
  }

  /**
   * Validator to check if the landline number is exactly 10 digits.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static landLineValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = /^[0-9]{10}$/.test(control.value);
      return isValid ? null : { 'invalidLandLine': { value: control.value } };
    };
  }

  /**
   * Validator to check if the mobile number is exactly 10 digits.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static mobileValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = /^[0-9]{10}$/.test(control.value);
      return isValid ? null : { 'invalidMobile': { value: control.value } };
    };
  }

  /**
   * Validator to check if the email address is in a valid format.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(control.value);
      return isValid ? null : { 'invalidEmail': { value: control.value } };
    };
  }

  /**
   * Validator to ensure that two email fields match.
   * Used to validate `emailAddress` and `confirmEmail` fields in a form group.
   * @returns A ValidatorFn that returns an error object if the emails do not match, otherwise null.
   */
  static emailMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.get('emailAddress');
      const confirmEmail = control.get('confirmEmail');

      return email && confirmEmail && email.value !== confirmEmail.value
        ? { emailMismatch: true }
        : null;
    };
  }

  /**
   * Validator to check if the South African identity number is valid.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static southAfricanIdValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = /^\d{13}$/.test(control.value);
      return isValid ? null : { 'invalidSouthAfricanId': { value: control.value } };
    };
  }

  /**
   * Validator to check if the South African passport number is valid.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static southAfricanPassportValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isValid = /^[A-Z]\d{8}$/.test(control.value);
      return isValid ? null : { 'invalidSouthAfricanPassport': { value: control.value } };
    };
  }

  /**
   * Validator to check if a minimum number of checkboxes are selected in a FormArray.
   * @param min The minimum number of checkboxes that must be selected.
   * @returns A ValidatorFn that returns an error object if the validation fails, otherwise null.
   */
  static minSelectedCheckboxes(min = 1): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + 1 : prev, 0);

      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  /**
   * Validator to check if a checkbox or toggle is checked (must be true).
   * @returns A ValidatorFn that returns an error object if the value is not true, otherwise null.
   */
  static mustBeTrue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value === true ? null : { 'mustBeTrue': { value: control.value } };
    };
  }
}
