import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { EstateAgentDto, EstateAgentPersonalInformationDto, EstateAgentStatusDto, PropertySectorDto } from 'src/app/services/property-matrixV2/models';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estate-agent-personal-information',
  templateUrl: './estate-agent-personal-information.component.html',
  styleUrls: ['./estate-agent-personal-information.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class EstateAgentPersonalInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  estateAgentForm: FormGroup;
  propertySectors: PropertySectorDto[] = [];
  estateAgentStatuses: EstateAgentStatusDto[] = [];

  selectedButton: string | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private _lookupService: LookupService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    this.estateAgentForm = this._formBuilder.group({
      status: new FormControl(null, [Validators.required]),
      sectors: new FormArray([], this.minSelectedCheckboxes(1)),
      otherSectors: new FormControl(null),
      yearsInOperation: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)])
    });

    this.fetchEstateAgentStatuses();
    this.fetchPropertySectors();
  }

  fetchEstateAgentStatuses() {
    this._lookupService.apiV1LookupGetEstateAgentStatusesGet().subscribe({
      next: (response: EstateAgentStatusDto[]) => {
        this.estateAgentStatuses = response;
      }
    });
  }

  fetchPropertySectors() {
    this._lookupService.apiV1LookupGetPropertySectorsGet().subscribe({
      next: (response: PropertySectorDto[]) => {
        this.propertySectors = response.sort((a, b) => a.intValue - b.intValue);
        this.addSectorCheckboxes();
      }
    });
  }

  addSectorCheckboxes() {
    this.propertySectors.forEach(() => {
      const control = new FormControl(false);
      (this.estateAgentForm.controls.sectors as FormArray).push(control);
    });
  }

  isOtherSectorChecked(): boolean {
    const sectorsArray = this.estateAgentForm.controls.sectors as FormArray;
    const otherSectorIndex = this.propertySectors.findIndex(sector => sector.value === 'Other');
    return otherSectorIndex !== -1 && sectorsArray.at(otherSectorIndex).value;
  }

  selectButton(buttonName: string): void {
    this.selectedButton = buttonName;
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + 1 : prev, 0);

      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  onSubmit() {
    if (this.estateAgentForm.valid) {
      const selectedSectors = this.estateAgentForm.value.sectors
        .map((checked: boolean, i: number) => checked ? this.propertySectors[i] : null)
        .filter((sector: PropertySectorDto | null) => sector !== null);

      const formValue: EstateAgentPersonalInformationDto = {
        ...this.estateAgentForm.value,
        status: this.estateAgentForm.get('status')?.value,
        sectors: selectedSectors
      };

      this._estateAgentRegistrationStateService.updatePersonalInformation(formValue);
      this.pageNumberEvent.emit(1);
      this.formSubmittedEvent.emit();
    }
  }
}
