import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, AfterViewInit {

  partnershipTypes = [
    'URBAN PLANNERS',
    'BROKERS / AGENTS',
    'PROPERTY VALUERS',
    'CONVEYANCERS'
  ];

  constructor(
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  ngAfterViewInit(): void {
    this.adjustBlockSizes();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.adjustBlockSizes();
  }

  private adjustBlockSizes(): void {
    const container = document.querySelector('.partnership-type-block-container') as HTMLElement;
    const blocks = Array.from(container.getElementsByClassName('partnership-type-block') as HTMLCollectionOf<HTMLElement>);

    const containerWidth = container.clientWidth;
    const blockCount = this.partnershipTypes.length;
    let maxBlockWidth = 180;
    let gapSize = 25;
    const maxGapSize = 75;

    if (containerWidth < 991) {
      blocks.forEach(block => {
        const blockWidth = Math.min(containerWidth, maxBlockWidth);
        block.style.width = `${blockWidth}px`;
        block.style.height = `${blockWidth}px`;
      });
      container.style.gap = '20px';
      container.style.padding = '0';
      return;
    }

    let blockWidth = Math.floor((containerWidth - (gapSize * (blockCount - 1))) / blockCount);

    if (blockWidth > maxBlockWidth) {
      blockWidth = maxBlockWidth;
      gapSize = Math.floor((containerWidth - (blockWidth * blockCount)) / (blockCount - 1));
    }

    if (gapSize > maxGapSize) {
      gapSize = maxGapSize;

      const totalBlockWidth = blockWidth * blockCount + gapSize * (blockCount - 1);
      const remainingSpace = containerWidth - totalBlockWidth;
      const padding = remainingSpace / 2;

      container.style.padding = `0 ${padding}px`;
    } else {
      container.style.padding = '0';
    }

    blocks.forEach(block => {
      block.style.width = `${blockWidth}px`;
      block.style.height = `${blockWidth}px`;
    });

    container.style.gap = `${gapSize}px`;
  }

  onTypeClick(type: string): void {
    if (type === 'URBAN PLANNERS') {
      this.router.navigate(['/planner-registration']);
      GlobalMethods.scrollToTop();
    } else if (type === 'BROKERS / AGENTS') {
      this.router.navigate(['/estate-agent-registration']);
      GlobalMethods.scrollToTop();
    } else {
      this.openDialog();
    }
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }
}
