import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';

import { SideMenuItem } from '../admin-side-menu/admin-side-menu.component';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss', '../../../../css/2-modules/_admin-portal.scss']
})
export class AdminProfileComponent implements OnInit {

  loading: boolean = false;
  isSideMenuVisible: boolean = true;

  menuType: string = 'profile';
  currentHeading: string | null = null;
  currentDescription: string | null = null;

  constructor() { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  toggleSideMenu(): void {
    this.isSideMenuVisible = !this.isSideMenuVisible;
  }

  onSideMenuChange(selectedItem: SideMenuItem): void {
    this.currentHeading = selectedItem.value;
    this.currentDescription = selectedItem.description;
    GlobalMethods.scrollToTop();
  }
}
