<main class="main-container">
    <div class="row paragraph mt-3">
        <div class="col-12 heading-2">
            Contract Agreement
        </div>
    </div>
    <form [formGroup]="contractAgreementForm" (ngSubmit)="onSubmit()">
        <div *ngIf="isPrincipal">
            <div class="section-container">
                <div class="row paragraph mt-3">
                    <div class="col-12 custom-checkbox-container">
                        <input type="checkbox" id="isCollaborationAgreementProvided"
                            formControlName="isCollaborationAgreementProvided" class="custom-checkbox">
                        <label for="isCollaborationAgreementProvided" class="custom-checkbox-label">
                            I will provide the collaboration agreement to all agents under my supervision and ensure
                            their adherence to its terms and conditions.
                        </label>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <div class="row paragraph mt-3">
                    <p>Thank you for your time! Please keep an eye on your inbox.</p>
                    <p>Once you are endorsed, you will be able to generate links for your team of agents.</p>
                    <p>Training videos and assigned tasks will be accessible via your profile.</p>
                </div>
            </div>
        </div>
        <div *ngIf="!isPrincipal">
            <div class="section-container">
                <div class="row paragraph mt-3">
                    <div class="col-12 custom-checkbox-container">
                        <input type="checkbox" id="isCollaborationAgreementRead"
                            formControlName="isCollaborationAgreementRead" class="custom-checkbox">
                        <label for="isCollaborationAgreementRead" class="custom-checkbox-label">
                            I confirm that I have read the Collaboration Agreement signed by my principal.
                        </label>
                    </div>
                </div>
                <div class="row paragraph">
                    <div class="col-12 custom-checkbox-container">
                        <input type="checkbox" id="isPledgeAccepted" formControlName="isPledgeAccepted"
                            class="custom-checkbox">
                        <label for="isPledgeAccepted" class="custom-checkbox-label">
                            I pledge to abide by all the rules, regulations and professional standards of Property
                            Matrix.
                        </label>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <div class="row paragraph mt-3">
                    <p>
                        Thank you for your time! Please keep an eye on your inbox.
                    </p>
                    <p>
                        Once you are endorsed, training videos and assigned tasks will be accessible via your profile.
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <button class="button" type="submit" [disabled]="contractAgreementForm.invalid">SUBMIT</button>
        </div>
    </form>
</main>