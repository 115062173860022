<main class="main-container">
    <div class="content-container">
        <div *ngIf="userType == 'estate-agent'" class="content paragraph">
            <span class="title">Collaboration Model</span>
            <div class="center-content">
                <b>
                    Before registering, please review the key points of our agreement.
                </b>
            </div>
            <div class="center-content">
                <b>
                    Business owners (principals) will receive a formal contract with the full terms.
                </b>
            </div>
            <div class="col-12 paragraph mt-4">
                <span class="bold">SUMMARY OF THE PROPERTY MATRIX MODEL:</span>
                <ul class="p-2">
                    <li>
                        <b>Registration:</b>
                        Principals need to register first, after which they will be provided with a link to share with
                        brokers/agents in their franchise.
                    </li>
                    <li>
                        <b>Access to platform:</b>
                        All nominated commercial brokers/agents will receive login details once a signed collaboration
                        agreement is received from their principal/business owner.
                    </li>
                    <li>
                        <b>Training guidelines:</b>
                        Access to online videos, explaining the Property Matrix model and workflow will be provided.
                    </li>
                    <li>
                        <b>Seller costs:</b>
                        Sellers incur no costs; Property Matrix covers the title deed, property investigation, detailed
                        planning report and marketing expenses.
                    </li>
                    <li>
                        <b>Risk-based services:</b>
                        No registration, planning report, or advertisement fees payable by brokers to list a property on
                        the platform. Property Matrix partners with brokers, providing them with professional services
                        and data to secure exclusive mandates and achieve additional, faster sales. Property Matrix acts
                        as a service provider, and our fee forms part of the commission payout by the transferring
                        attorney. The fee structure will be provided during training.
                    </li>
                    <li>
                        <b>Fee structure:</b>
                        Property Matrix acts as a service provider, and our value-based fee is only payable if
                        commission is earned. Payout is done by the transferring attorney. The fee structure will be
                        provided during training.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <span class="bold">PROPERTY LISTINGS:</span>
                <ul class="p-2">
                    <li>
                        <b>Value assessment:</b>
                        Brokers/Agents are required to visit the property, complete a questionnaire and conduct a
                        comparative market analysis (if required) to propose a market-related asking price. Insights
                        into market trends in your area are integral to our model.
                    </li>
                    <li>
                        <b>Planning report:</b>
                        Finalized upon receiving a signed exclusive mandate with a realistic, market-related asking
                        price.
                    </li>
                    <li>
                        <b>Conveyancing report:</b>
                        Our legal team will order the title deed and draft a basic conveyancing report upon receiving a
                        signed exclusive mandate and upon the Property Matrix legal team being confirmed as
                        conveyancers. This service is optional for sellers and brokers.
                    </li>
                    <li>
                        <b>Advertising:</b>
                        The property will be listed on our website with free access to the planning report for all
                        users. All Broker/Agent related queries will be directed to you.
                    </li>
                    <li>
                        <b>Legal and mortgage support:</b>
                        Conveyancers are available for legal advice and the transfer process and bond originators for
                        mortgage assistance.
                    </li>
                    <li>
                        <b>Professional fee:</b>
                        Payable only upon a successful sale of your listed property and leads referred to you by
                        Property Matrix. Our associated fee schedules will be included in our collaboration agreement
                        and presented during training.
                    </li>
                    <li>
                        <b>Valuation support:</b>
                        Desktop or formal valuations by registered professional valuers will be available if needed.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <span class="bold">Available in a future launch:</span>
                <ul class="p-2">
                    <li>
                        <b>Architectural support:</b>
                        Assistance in verifying approved building plans.
                    </li>
                    <li>
                        <b>Direct seller listings:</b>
                        Property Matrix to refer direct listings (leads) to a partnering broker/agent to visit the site,
                        conduct a value assessment and sign an exclusive mandate.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <p class="bold m-0">JOIN OUR NETWORK:</p>
                <p>
                    Proceed below to partner with us. Registration takes about 15-20 minutes.
                </p>
            </div>
            <div class="row paragraph mt-5">
                <div class="text-center">
                    <button class="button" type="button" (click)="proceed()">PROCEED</button>
                </div>
            </div>
        </div>

        <div *ngIf="userType == 'urban-planner'" class="content paragraph">
            <span class="title">Collaboration Agreement</span>
            <div class="col-12 paragraph mt-4">
                <ul class="p-2">
                    <li>
                        Upon approval as a Property Matrix 'associate planner', we will refer potential clients in your
                        area/metro to you at no cost.
                    </li>
                    <li>
                        Our in-house planners prepare a baseline report (70-80% completion) with deeds and planning
                        data. Your role as a registered planner involves confirming existing land use rights and
                        offering insights on potential land use and construction scope according to municipal policy.
                    </li>
                    <li>
                        Any subsequent LUMS work from the client will be directed to you as an independent consultant,
                        not as a Property Matrix associate. Planning applications can be lodged via your firm with your
                        branding.
                    </li>
                    <li>
                        Professional planning fee guidelines will be provided on our website. We encourage adherence to
                        these rates to correct market discrepancies. Discussions may be initiated if rates are
                        discounted more than 30% from our guidelines.
                    </li>
                    <li>
                        For Property Matrix-listed properties, the investigation report is seen as a 'quote' and is
                        mostly completed at risk. We remunerate R10,000 plus VAT for planning reports that facilitate
                        successful property transactions.
                    </li>
                    <li>
                        Clients not pursuing a property transaction can order Level 1, 2, or 3 investigation reports via
                        our platform for a fee.
                    </li>
                    <li>
                        Property Matrix refers potential clients to the most suitable planner in our network.
                    </li>
                    <li>
                        You'll receive remuneration for completed investigation reports ordered by clients: From R1,000
                        for Level 1 and from R4,000 for Level 2 reports contributed to.
                    </li>
                    <li>
                        Level 3 reports are project-specific, allowing collaboration with an extended network and
                        providing cost and timeframe quotations to the client.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <p>
                    Upon successful registration, an online session will delve deeper into the Property Matrix model,
                    the summary above, and our workflow procedures.
                </p>
            </div>
            <div class="row paragraph mt-5">
                <div class="text-center">
                    <button class="button" type="button" (click)="proceed()">PROCEED</button>
                </div>
            </div>
        </div>
    </div>
</main>