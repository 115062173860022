/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MultiplePropertyListingDto } from '../models/multiple-property-listing-dto';
import { PropertyListingDto } from '../models/property-listing-dto';

@Injectable({
  providedIn: 'root',
})
export class PropertyListingService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1PropertyListingGetPropertyListingByIdGet
   */
  static readonly ApiV1PropertyListingGetPropertyListingByIdGetPath = '/api/v1/PropertyListing/GetPropertyListingById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetPropertyListingByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPropertyListingByIdGet$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<PropertyListingDto>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetPropertyListingByIdGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<PropertyListingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetPropertyListingByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPropertyListingByIdGet(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyListingDto> {

    return this.apiV1PropertyListingGetPropertyListingByIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<PropertyListingDto>) => r.body as PropertyListingDto)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingDeletePropertyListingDelete
   */
  static readonly ApiV1PropertyListingDeletePropertyListingDeletePath = '/api/v1/PropertyListing/DeletePropertyListing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingDeletePropertyListingDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDeletePropertyListingDelete$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingDeletePropertyListingDeletePath, 'delete');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingDeletePropertyListingDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDeletePropertyListingDelete(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingDeletePropertyListingDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetAllPropertyListingsGet
   */
  static readonly ApiV1PropertyListingGetAllPropertyListingsGetPath = '/api/v1/PropertyListing/GetAllPropertyListings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetAllPropertyListingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAllPropertyListingsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<PropertyListingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetAllPropertyListingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<PropertyListingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetAllPropertyListingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAllPropertyListingsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<PropertyListingDto>> {

    return this.apiV1PropertyListingGetAllPropertyListingsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<PropertyListingDto>>) => r.body as Array<PropertyListingDto>)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingSavePropertyListingDataPost
   */
  static readonly ApiV1PropertyListingSavePropertyListingDataPostPath = '/api/v1/PropertyListing/SavePropertyListingData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingSavePropertyListingDataPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingSavePropertyListingDataPost$Response(params?: {
    custom?: boolean;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingSavePropertyListingDataPostPath, 'post');
    if (params) {
      rb.query('custom', params.custom, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingSavePropertyListingDataPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingSavePropertyListingDataPost(params?: {
    custom?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingSavePropertyListingDataPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetAllMultiplePropertyListingsGet
   */
  static readonly ApiV1PropertyListingGetAllMultiplePropertyListingsGetPath = '/api/v1/PropertyListing/GetAllMultiplePropertyListings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetAllMultiplePropertyListingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAllMultiplePropertyListingsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<MultiplePropertyListingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetAllMultiplePropertyListingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<MultiplePropertyListingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetAllMultiplePropertyListingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAllMultiplePropertyListingsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<MultiplePropertyListingDto>> {

    return this.apiV1PropertyListingGetAllMultiplePropertyListingsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<MultiplePropertyListingDto>>) => r.body as Array<MultiplePropertyListingDto>)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost
   */
  static readonly ApiV1PropertyListingAddOrUpdateMultiplePropertyListingPostPath = '/api/v1/PropertyListing/AddOrUpdateMultiplePropertyListing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost$Response(params?: {
    context?: HttpContext
    body?: MultiplePropertyListingDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingAddOrUpdateMultiplePropertyListingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost(params?: {
    context?: HttpContext
    body?: MultiplePropertyListingDto
  }
): Observable<void> {

    return this.apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

}
