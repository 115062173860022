import { PropertyMatrixConfigurationV2 } from 'src/app/services/property-matrixV2/property-matrix-configuration-v-2';
import { FileManagementService } from 'src/app/services/property-matrixV2/services/file-management.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileManagementExtensionService extends FileManagementService {

  constructor(
    protected config: PropertyMatrixConfigurationV2,
    protected http: HttpClient
  ) {
    super(config, http);
  }

  async uploadFile(file: any): Promise<string> {
    const formData = new FormData();
    formData.append(`file`, file);

    return new Promise<string>((resolve, reject) => {
      this.http.post(`${environment.api}${FileManagementService.ApiV1FileManagementUploadFilePostPath}`, formData)
        .subscribe({
          next: (result) => {
            var res = result.toString();
            resolve(res);
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  getFileDownloadUrl(fileId: string): Observable<string> {
    return this.http.get<string>(`${environment.api}/api/v1/FileManagement/GetFile/${fileId}`);
  }
}
