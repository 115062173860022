<main class="main-container">
    <form [formGroup]="contractDetailsForm" (ngSubmit)="onSubmit()">
        <div class="content-padding">

            <div class="row paragraph mt-3">
                <div class="col-12">
                    <h2 class="step-heading">Step 3: Contract Details</h2>
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-12 heading-3">
                    Conveyancing
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-12 paragraph">
                    We strongly recommend using our in-house team of specialised conveyancers. Click
                    <a (click)="openBenefitsDialog($event)" class="link-effect">here</a>
                    for benefits.
                </div>
            </div>

            <div class="row paragraph mt-4">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    Will the Seller(s) make use of the Property Matrix legal team for conveyancing purposes?
                </label>
                <mat-radio-group formControlName="usePropertyMatrixLegalTeam"
                    (change)="onConveyancerRadioChange($event)">
                    <mat-radio-button class="mt-1" [value]="true">
                        Yes
                    </mat-radio-button>
                    <mat-radio-button class="mt-1" [value]="false">
                        No, they wish to appoint their own conveyancer
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="usePropertyMatrixLegalTeam === true">
                <p class="paragraph mt-3">
                    Thank you for choosing Property Matrix, our conveyancing team will be in contact with you shortly.
                </p>
            </div>

            <div *ngIf="usePropertyMatrixLegalTeam === false" formGroupName="conveyancerDetail">
                <div class="row paragraph mt-3">
                    <div class="bold">
                        Details of the preferred conveyancer:
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="conveyancerCompanyName">Company name:</label>
                    </div>
                    <div class="col-7">
                        <input required id="conveyancerCompanyName" class="default-input" formControlName="companyName"
                            type="text" maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="conveyancerContactPerson">Contact person:</label>
                    </div>
                    <div class="col-7">
                        <input required id="conveyancerContactPerson" class="default-input"
                            formControlName="contactPerson" type="text" maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="conveyancerEmailAddress">Email address:</label>
                    </div>
                    <div class="col-7">
                        <input required id="conveyancerEmailAddress" class="default-input"
                            formControlName="emailAddress" type="text" [ngClass]="{'invalid-input':
                                contractDetailsForm.get('conveyancerDetail.emailAddress').invalid &&
                                contractDetailsForm.get('conveyancerDetail.emailAddress').touched}">
                        <small class="burnt-red" *ngIf="
                                contractDetailsForm.get('conveyancerDetail.emailAddress').invalid &&
                                contractDetailsForm.get('conveyancerDetail.emailAddress').touched">
                            * Invalid email address.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="conveyancerMobileNumber">Contact number - mobile:</label>
                    </div>
                    <div class="col-7">
                        <input required id="conveyancerMobileNumber" class="default-input"
                            formControlName="mobileNumber" type="text" [ngClass]="{'invalid-input':
                                contractDetailsForm.get('conveyancerDetail.mobileNumber').invalid &&
                                contractDetailsForm.get('conveyancerDetail.mobileNumber').touched}">
                        <small class="burnt-red" *ngIf="
                                contractDetailsForm.get('conveyancerDetail.mobileNumber').invalid &&
                                contractDetailsForm.get('conveyancerDetail.mobileNumber').touched">
                            * Contact number must contain exactly 10 digits.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="conveyancerOfficeNumber">Contact number - office:</label>
                    </div>
                    <div class="col-7">
                        <input required id="conveyancerOfficeNumber" class="default-input"
                            formControlName="officeNumber" type="text" [ngClass]="{'invalid-input':
                                contractDetailsForm.get('conveyancerDetail.officeNumber').invalid &&
                                contractDetailsForm.get('conveyancerDetail.officeNumber').touched}">
                        <small class="burnt-red" *ngIf="
                                contractDetailsForm.get('conveyancerDetail.officeNumber').invalid &&
                                contractDetailsForm.get('conveyancerDetail.officeNumber').touched">
                            * Contact number must contain exactly 10 digits.
                        </small>
                    </div>
                </div>
            </div>

            <div class="row paragraph mt-4">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    Is the Seller already connected to any partnering urban planner who they prefer to work with?
                </label>
                <mat-radio-group formControlName="connectedToUrbanPlanner" (change)="updateUrbanPlannerValidation()">
                    <mat-radio-button class="mt-1" [value]="true">
                        Yes
                    </mat-radio-button>
                    <mat-radio-button class="mt-1" [value]="false">
                        No, Property Matrix can assign a planner to investigate the property
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="connectedToUrbanPlanner === true" formGroupName="urbanPlannerDetail">
                <div class="row paragraph mt-3">
                    <div class="bold">
                        Details of urban planner:
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="plannerName">Planner name:</label>
                    </div>
                    <div class="col-7">
                        <input required id="plannerName" class="default-input" formControlName="name" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="plannerSurname">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input required id="plannerSurname" class="default-input" formControlName="surname" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="plannerCompany">Company:</label>
                    </div>
                    <div class="col-7">
                        <input id="plannerCompany" class="default-input" formControlName="company" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="plannerCity">City/town:</label>
                    </div>
                    <div class="col-7">
                        <input required id="plannerCity" class="default-input" formControlName="city" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="plannerEmail">Email:</label>
                    </div>
                    <div class="col-7">
                        <input required id="plannerEmail" class="default-input" formControlName="email" type="text"
                            [ngClass]="{'invalid-input':
                                contractDetailsForm.get('urbanPlannerDetail.email').invalid &&
                                contractDetailsForm.get('urbanPlannerDetail.email').touched}">
                        <small class="burnt-red" *ngIf="
                                contractDetailsForm.get('urbanPlannerDetail.email').invalid &&
                                contractDetailsForm.get('urbanPlannerDetail.email').touched">
                            * Invalid email address.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="plannerContactNumber">Mobile/office nr:</label>
                    </div>
                    <div class="col-7">
                        <input required id="plannerContactNumber" class="default-input" formControlName="phone"
                            type="text" [ngClass]="{'invalid-input':
                                contractDetailsForm.get('urbanPlannerDetail.phone').invalid &&
                                contractDetailsForm.get('urbanPlannerDetail.phone').touched}">
                        <small class="burnt-red" *ngIf="
                                contractDetailsForm.get('urbanPlannerDetail.phone').invalid &&
                                contractDetailsForm.get('urbanPlannerDetail.phone').touched">
                            * Contact number must contain exactly 10 digits.
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="contractDetailsForm.invalid">Submit</button>
            </div>
        </div>
    </form>
</main>