<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="title-container">
    <div class="mt-4">
        <h5>Documents</h5>
        <p>All documents related to this property</p>
    </div>
    <div class="mt-4">
        <h6>Planning Report and Addendums</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSource" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let document" class="bold">
                    #{{ document?.documentNumber }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedFileName">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedFileName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedByUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedByUserEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ formatDate(document?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    <a [href]="document?.downloadUrl" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>

        </mat-table>
    </div>
</mat-card>