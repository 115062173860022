import { PropertyTypeDto, RiskscapeExistingLandUseAndFeatureDto, RiskscapePropertyInfoDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyDetailComponent implements OnInit {

  @Input() riskscapePropertyInfoData: RiskscapePropertyInfoDto;
  @Input() riskscapeExistingLandUseAndFeatureData: RiskscapeExistingLandUseAndFeatureDto;

  @Output() propertyType = new EventEmitter<string>();

  propertyTypes: PropertyTypeDto[] = [];
  selectedPropertyType: string | null = null;

  constructor(
    private lookupService: LookupService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getPropertyTypes();
  }

  onPropertyTypeChange(event: MatSelectChange) {
    this.selectedPropertyType = event.value === null ? null : event.value;
    this.propertyType.emit(this.selectedPropertyType);
  }

  private getPropertyTypes(): void {
    this.lookupService.apiV1LookupGetPropertyTypesGet().subscribe({
      next: (response: PropertyTypeDto[]) => {
        this.propertyTypes = response;
        this.setSelectedPropertyType();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load property types.');
      }
    });
  }

  private setSelectedPropertyType(): void {
    switch (this.riskscapePropertyInfoData?.propertyType) {
      case "E":
        this.selectedPropertyType = "Erf - Full Title";
        break;
      case "Erf - Full Title":
        this.selectedPropertyType = "Erf - Full Title";
        break;
      case "Erf - Sectional Title":
        this.selectedPropertyType = "Erf - Sectional Title";
        break;
      case "H":
      case "Agricultural Holding":
        this.selectedPropertyType = "Agricultural Holding";
        break;
      case "F":
      case "Farm Portion":
        this.selectedPropertyType = "Farm Portion";
        break;
      default:
        this.selectedPropertyType = "None";
        break;
    }
  }
}
