<main class="main-container">

    <section *ngIf="userType == 'estate-agent'" class="content">
        <h1 class="heading">Introduction For Agents</h1>
        <div class="section">
            <span class="title uppercase">This video answers:</span>
            <ul class="list-items">
                <li>Why Property Matrix? <span class="coral-text">(The Need)</span></li>
                <li>What is Property Matrix <span class="coral-text">(The Solution)</span></li>
                <li>Why Commercial Brokers and Estate Agents? <span class="coral-text">(The Partnership)</span></li>
            </ul>
        </div>
        <div class="section">
            <span class="title">Watch the video before you proceed with the registration process.</span>
            <div class="video-player-container mt-3">
                <video *ngIf="videoLoaded" class="video-player" width="100%" height="auto" controls>
                    <source src="assets/videos/stock-video.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div *ngIf="!videoLoaded" class="error-message">
                    The video could not be loaded. Please try again later.
                </div>
            </div>
        </div>
        <div class="section">
            <div class="text-center mt-3">
                <button mat-button (click)="proceed()" class="button-proceed heading-4 uppercase">
                    Proceed
                </button>
            </div>
        </div>
    </section>

    <section *ngIf="userType == 'urban-planner'" class="content">
        <h1 class="heading">Introduction For Planners</h1>
        <div class="section">
            <span class="title uppercase">This video answers:</span>
            <ul class="list-items">
                <li>Why Property Matrix? <span class="coral-text">(The Need)</span></li>
                <li>What is Property Matrix <span class="coral-text">(The Solution)</span></li>
                <li>Why Professional Planners? <span class="coral-text">(The Network)</span></li>
            </ul>
        </div>
        <div class="section">
            <span class="title">Click on play before you proceed with the registration process.</span>
            <div class="video-player-container mt-3">
                <video *ngIf="videoLoaded" class="video-player" width="100%" height="auto" controls>
                    <source src="assets/videos/stock-video.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div *ngIf="!videoLoaded" class="error-message">
                    The video could not be loaded. Please try again later.
                </div>
            </div>
        </div>
        <div class="section">
            <div class="text-center mt-3">
                <button mat-button (click)="proceed()" class="button-proceed heading-4 uppercase">
                    Proceed
                </button>
            </div>
        </div>
    </section>
</main>