<main class="main-container">
    <div class="content-padding">
        <div class="row paragraph mt-3">
            <div class="col-12">
                <h2 class="step-heading">Step 4: Upload Documents</h2>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <p class="paragraph bold">
                    Important note:
                </p>
            </div>
            <div class="col-12">
                <ul class="paragraph m-0 px-2">
                    <li>
                        Documents and pictures to be <b>named correctly</b>
                    </li>
                    <li>
                        Upload documents/pictures in one of the following formats: <b>pdf, jpg or png</b>.
                    </li>
                    <li>
                        Max of 2MB per upload possible
                    </li>
                </ul>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="col-12">
                <h4 class="heading-4">
                    Mandatory Uploads
                </h4>
            </div>
            <div class="col-12">
                <p class="bold">Upload the following documents:</p>
            </div>
        </div>
        <div class="row paragraph">
            <div class="upload-section" *ngFor="let doc of documents; let i = index"
                [ngClass]="{ 'required-border': doc.required }">
                <mat-card class="upload-card" [ngClass]="{ 'no-border': isFileUploaded(i) }"
                    (click)="fileInput.click()">
                    <div>
                        <mat-checkbox [checked]="isFileUploaded(i)" disabled></mat-checkbox>
                        <span class="paragraph ms-2">{{ doc.description }}</span>
                    </div>
                    <div class="upload-actions">
                        <span *ngIf="!isFileUploaded(i)" class="paragraph">Click to upload</span>
                        <button mat-button *ngIf="!isFileUploaded(i)" matTooltip="Upload" matTooltipPosition="right"
                            matTooltipShowDelay="1000">
                            <mat-icon class="material-icons-outlined">upload</mat-icon>
                        </button>
                        <button mat-button *ngIf="isFileUploaded(i)" (click)="downloadFile(i, $event)"
                            matTooltip="Download" matTooltipPosition="left" matTooltipShowDelay="1000">
                            <mat-icon class="material-icons-outlined">download</mat-icon>
                        </button>
                        <button mat-button *ngIf="isFileUploaded(i)" (click)="onRemoveFile(i, $event)"
                            matTooltip="Delete" matTooltipPosition="right" matTooltipShowDelay="1000">
                            <mat-icon class="material-icons-outlined">delete</mat-icon>
                        </button>
                    </div>
                    <input type="file" class="file-input" (change)="onFileSelected($event, i)" hidden #fileInput>
                </mat-card>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="col-12">
                <p class="paragraph">
                    Seller verification:
                    <br>
                    <b>If natural person(s):</b> ID of seller(s) or passport (only if not a resident of RSA). If married
                    in community: copy of spouse's ID/passport.
                    <br>
                    <b>If juristic person</b> (company/cc/NPO): registration certificate. If a trust: letter of
                    authority.
                    <br>
                    <b>If multiple members:</b> resolution authorising the representative and ID of the representative.
                </p>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <p class="paragraph">
                    Additional information:
                    <br>
                    <b>If property is in eThekwini:</b> a copy of the water & electricity bill.
                    <br>
                    <b>If multiple properties:</b> a map, indicating all the adjacent properties which will form part of
                    the development opportunity.
                </p>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <app-file-upload-multiple></app-file-upload-multiple>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <p class="paragraph">
                    <b>Pictures:</b>
                    <br>
                    3-20 quality pictures of the street view and interior & exterior of all land uses and features on
                    the property
                </p>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <app-file-upload-multiple></app-file-upload-multiple>
        </div>
        <div class="row paragraph mt-5">
            <div class="col-12">
                <h4 class="heading-4">
                    Property Information
                </h4>
            </div>
            <div class="col-12">
                <ul class="paragraph m-0 px-2">
                    <li>
                        It will be beneficial to your investigation if you provide us with as much information as
                        possible.
                    </li>
                    <li>
                        Click <a (click)="openAdditionalInformationDialog($event)" class="link-effect">here</a> for
                        examples of what we require.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <app-file-upload-multiple></app-file-upload-multiple>
        </div>
        <div class="row paragraph mt-5">
            <div class="col-12">
                <h4 class="heading-4">
                    Consent from Industry Professionals:
                </h4>
            </div>
            <div class="col-12">
                <p class="paragraph">
                    Property Matrix respects the intellectual property rights of related professionals. It is therefore
                    essential that they provide consent, allowing us to share their reports or maps and to make such
                    available for download on our website.
                </p>
            </div>
            <div class="col-12">
                <p class="paragraph">
                    <b>Please note:</b> Property Matrix will reach out to industry professionals to request their
                    consent and offer this platform to promote their company and services.
                </p>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="!allRequiredFilesUploaded()" (click)="onSubmit()">
                    SUBMIT
                </button>
            </div>
        </div>
    </div>
</main>