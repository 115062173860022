<main class="main-container">
    <form [formGroup]="ownershipDetailsForm" (ngSubmit)="onSubmit()">
        <div class="content-padding">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <h2 class="step-heading">Step 1: Ownership Details</h2>
                </div>
            </div>

            <div class="row paragraph mt-4">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    The property to be sold is registered in the name of:
                </label>
                <mat-radio-group formControlName="propertyOwnershipType" (change)="onOwnershipTypeChange($event)">
                    <div *ngFor="let type of propertyOwnershipTypes">
                        <mat-radio-button class="mt-1" [value]="type">
                            {{ type.value }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>

            <div *ngIf="selectedPropertyOwnershipType?.intValue === 1 || selectedPropertyOwnershipType?.intValue === 3"
                class="mt-5">
                <div class="row paragraph mt-4">
                    <div class="col-12">
                        <h4 class="person-heading">Details of natural person(s)</h4>
                    </div>
                    <div class="col-12">
                        <p class="bold">
                            <span class="burnt-red">|</span>
                            Record details of all property owners (natural persons), as per the title deed of the
                            property:
                        </p>
                    </div>
                </div>

                <mat-card class="table-container mt-2">
                    <div class="add-person paragraph col-12">
                        <button class="add-person-button" type="button" (click)="addNaturalPerson()">
                            <mat-icon class="add-person-icon bold">add</mat-icon>
                            <span class="add-person-label">Add Owner/Co-owner</span>
                        </button>
                    </div>
                    <mat-table [dataSource]="naturalPersonTableDataSource" class="person-table">
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>

                        <ng-container matColumnDef="number">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Number
                            </mat-header-cell>
                            <mat-cell *matCellDef="let naturalPerson">
                                {{ naturalPerson?.index }}.
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let naturalPerson">
                                {{ naturalPerson?.fullNames + ' ' + naturalPerson?.surname }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="entity">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Spouse (In Community)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let naturalPerson">
                                {{ (naturalPerson?.spouseDetail?.fullNames ?? '') + ' ' +
                                (naturalPerson?.spouseDetail?.surname ?? '') }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Edit
                            </mat-header-cell>
                            <mat-cell *matCellDef="let naturalPerson">
                                <button class="table-button" type="button" (click)="editNaturalPerson(naturalPerson)">
                                    <mat-icon class="table-button-icon material-icons-outlined">edit</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Delete
                            </mat-header-cell>
                            <mat-cell *matCellDef="let naturalPerson">
                                <button class="table-button" type="button" (click)="deleteNaturalPerson(naturalPerson)">
                                    <mat-icon class="table-button-icon material-icons-outlined">delete</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                    </mat-table>
                </mat-card>
            </div>

            <div *ngIf="selectedPropertyOwnershipType?.intValue === 2 || selectedPropertyOwnershipType?.intValue === 3"
                class="mt-5">
                <div class="row paragraph mt-4">
                    <div class="col-12">
                        <h4 class="person-heading">Details of juristic person(s)</h4>
                    </div>
                    <div class="col-12">
                        <p class="bold">
                            <span class="burnt-red">|</span>
                            Record details of all property owners (juristic persons/entities), as per the title deed of
                            the property:
                        </p>
                    </div>
                </div>

                <mat-card class="table-container mt-2">
                    <div class="add-person paragraph col-12">
                        <button class="add-person-button" type="button" (click)="addJuristicPerson()">
                            <mat-icon class="add-person-icon bold">add</mat-icon>
                            <span class="add-person-label">Add Juristic Person/Entity</span>
                        </button>
                    </div>
                    <mat-table [dataSource]="juristicPersonTableDataSource" class="person-table">
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>

                        <ng-container matColumnDef="number">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Number
                            </mat-header-cell>
                            <mat-cell *matCellDef="let juristicPerson">
                                {{ juristicPerson?.index }}.
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Name of Entity
                            </mat-header-cell>
                            <mat-cell *matCellDef="let juristicPerson">
                                {{ juristicPerson?.fullName }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="entity">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Representative
                            </mat-header-cell>
                            <mat-cell *matCellDef="let juristicPerson">
                                {{ (juristicPerson?.legalRepresentative?.title ?? '') + ' ' +
                                (juristicPerson?.legalRepresentative?.fullNames ?? '') + ' ' +
                                (juristicPerson?.legalRepresentative?.surname ?? '') }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Edit
                            </mat-header-cell>
                            <mat-cell *matCellDef="let juristicPerson">
                                <button class="table-button" type="button" (click)="editJuristicPerson(juristicPerson)">
                                    <mat-icon class="table-button-icon material-icons-outlined">edit</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <mat-header-cell *matHeaderCellDef class="header-cell">
                                Delete
                            </mat-header-cell>
                            <mat-cell *matCellDef="let juristicPerson">
                                <button class="table-button" type="button"
                                    (click)="deleteJuristicPerson(juristicPerson)">
                                    <mat-icon class="table-button-icon material-icons-outlined">delete</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                    </mat-table>
                </mat-card>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="ownershipDetailsForm.invalid">Submit</button>
            </div>
        </div>
    </form>
</main>