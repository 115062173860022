<main class="dialog-container">
    <div class="dialog-content">
        <div *ngIf="actionType === 'unverify'">
            <span class="paragraph bold user-dialog-heading">Unverify User</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">
                    Are you sure you want to unverify user
                    <span class="bold">{{ user.fullName }}</span>?
                </span>
            </div>
        </div>
        <div *ngIf="actionType === 'remove'">
            <span class="paragraph bold user-dialog-heading">Remove User</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">
                    Are you sure you want to remove user
                    <span class="bold">{{ user.fullName }}</span>?
                    You will not be able to retrieve their profile again.
                </span>
            </div>
        </div>
        <div *ngIf="actionType === 'viewProfile'">
            <span class="paragraph bold user-dialog-heading">View Profile</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">
                    Viewing profile for user
                    <span class="bold">{{ user.fullName }}</span>.
                </span>
            </div>
        </div>
        <div *ngIf="actionType === 'archive'">
            <span class="paragraph bold user-dialog-heading">Archive User</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">
                    Are you sure you want to archive user
                    <span class="bold">{{ user.fullName }}</span>?
                </span>
            </div>
        </div>
        <div *ngIf="actionType === 'edit'">
            <span class="paragraph bold user-dialog-heading">Edit User</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">
                    Are you sure you want to edit user
                    <span class="bold">{{ user.fullName }}</span>?
                </span>
            </div>
        </div>
    </div>
    <div class="button-group">
        <button class="footer-button background-cloud" (click)="closeDialog()">Cancel</button>
        <button class="footer-button background-coral" (click)="confirmAction()">Confirm</button>
    </div>
</main>