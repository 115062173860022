import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-urban-planner-sign-contract',
  templateUrl: './urban-planner-sign-contract.component.html',
  styleUrls: ['./urban-planner-sign-contract.component.scss']
})
export class UrbanPlannerSignContractComponent implements OnInit {

  constructor(
    private _router: Router,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void { }

  onSubmit(): void {
    this._router.navigateByUrl('');
    this._notificationService.showSuccessMessage('Success', 'Planner registration was successful.');
  }
}
