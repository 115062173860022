import { ContractDetailDto, OwnershipDetailDto, PropertyDetailDto, SellMyPropertyDto, UploadFileDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SellMyPropertyService } from 'src/app/services/property-matrixV2/services';
import { UploadDocumentDetailsVm } from 'src/app/services/property-matrix/models';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss']
})
export class PropertyFormComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private route: Router,
    public dialog: MatDialog,
    private pageTitle: Title,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private sellMyPropertyService: SellMyPropertyService,
  ) {
    this.pageTitle.setTitle('Sell Your Property Form')
  }

  pageNumber: number = 1;
  ownershipDetailsId: number = 0;

  loading: boolean = false;
  isMultipleTenants: boolean = false;
  isMultipleProperty: boolean = false;
  loadUploadDocumentBlocks: boolean = false;

  ownershipDetails: OwnershipDetailDto;

  sideNavPage: KeyValuePair = { key: 0, value: '' };

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'OWNERSHIP DETAILS' },
    { key: 2, value: 'PROPERTY DETAILS' },
    { key: 3, value: 'CONTRACT DETAILS' },
    { key: 4, value: 'UPLOAD DOCUMENTS' },
  ];

  sideNavPages: KeyValuePair[] = [
    { key: 1, value: 'How to locate your property and determine the correct property description' },
    { key: 2, value: 'Factors considered for Value Assessment' },
    { key: 3, value: 'Formal Valuation Reports' },
    { key: 4, value: '' },
    { key: 5, value: 'Required if advertising joint properties as one development opportunity' },
  ];

  uploadDocumentDetails: UploadDocumentDetailsVm = null;
  files: UploadFileDto[] = [];
  cadastre: UploadFileDto;

  sellMyPropertyDto: SellMyPropertyDto = {
    ownershipDetail: null,
    contractDetail: null,
    propertyDetails: null
  };

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  selectPage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }

  changePage($event: number) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      this.scrollToTop();
    }
  }

  loadUploadBlocks($event: boolean) {
    this.loadUploadDocumentBlocks = $event;
  }

  assignOwnershipDetails($event: OwnershipDetailDto) {
    this.sellMyPropertyDto.ownershipDetail = $event;
    this.ownershipDetails = this.sellMyPropertyDto.ownershipDetail;
  }

  assignPropertyDetails($event: PropertyDetailDto) {
    this.sellMyPropertyDto.propertyDetails = $event;
    this.isMultipleProperty = this.sellMyPropertyDto.propertyDetails.wishToSell.intValue > 1;
    this.isMultipleTenants = this.sellMyPropertyDto.propertyDetails.tenantedBuildingsAmount > 1;
  }

  assignCadastre($event: UploadFileDto) {
    this.cadastre = $event
  }

  assignContractDetails($event: ContractDetailDto) {
    this.sellMyPropertyDto.contractDetail = $event
  }

  assignUploadDocumentDetails($event: UploadFileDto[]) {
    this.files = $event;
    this.submitForm();
  }

  submitForm() {
    this.loading = true;
    this.sellMyPropertyService.apiV1SellMyPropertySaveSellingMyPropertyFormPost({
      body: this.sellMyPropertyDto
    }).subscribe({
      next: (result) => {
        this.uploadFiles(result);
      },
      error: (_error) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Failed to upload files.');
      }
    });
  }

  uploadFiles(propertyDetailId: string) {
    const formData = new FormData();

    formData.append(`${propertyDetailId}|${this.cadastre.fileType.id}|${this.cadastre.fileType.category}`, this.cadastre.file);

    this.files?.forEach(file => {
      formData.append(`${propertyDetailId}|${file.fileType.id}|${file.fileType.category}`, file.file);
    });

    this.httpClient.post(environment.api + '/api/v1/SellMyProperty/UploadSellMyPropertyFiles', formData)
      .pipe(
        catchError((_error) => {
          return of(
            this.notificationService.showErrorMessage('Error', 'Failed to upload files.')
          );
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.route.navigateByUrl('/selling-my-property/capture/finished');
      });
  }

  scrollToTop() {
    GlobalMethods.scrollToId('top-page')
  }

  openNav($event: any) {
    this.sideNavPage = this.sideNavPages.find(f => f.key == $event);
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }
}
