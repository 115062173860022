import { UrbanPlannerRegistrationStateService } from 'src/app/shared/services/registration/urban-planner-registration-state/urban-planner-registration-state.service';
import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { UserManagementService } from 'src/app/services/property-matrixV2/services';
import { UserDto } from 'src/app/services/property-matrixV2/models';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../../css/2-modules/_forms.scss'],
})
export class RegisterComponent {

  @Output() pageNumberEvent = new EventEmitter<number>();

  loading: boolean = false;
  isPrincipal: boolean = false;
  isRsaResident: boolean = true;
  confirmConsent: boolean = false;

  inviteId: string | null = null;
  userType: string | null = null;

  emailPattern: string = GlobalConstants.emailPattern;
  passwordPattern: string = GlobalConstants.passwordPattern;

  userForm: UserDto = {
    confirmEmail: '',
    confirmMobile: '',
    email: '',
    idNumber: '',
    isSouthAfricanResident: this.isRsaResident,
    landLine: '',
    mobile: '',
    name: '',
    passportNumber: '',
    surname: '',
    userName: '',
    password: '',
    passwordConfirm: ''
  };

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _keycloak: KeycloakService,
    private _authService: UserManagementService,
    private _notificationService: NotificationService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService,
    private _urbanPlannerRegistrationStateService: UrbanPlannerRegistrationStateService
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.userType = params['userType'];
      if (this.userType === 'estate-agent') {
        this.isPrincipal = params['isPrincipal'] === 'true';
        this.inviteId = params['inviteId'];
      }
    });

    GlobalMethods.scrollToTop();
  }

  onResidencyChange(event: any): void {
    this.isRsaResident = event.value;
    this.userForm.isSouthAfricanResident = this.isRsaResident
  }

  register(): void {
    switch (this.userType) {
      case 'website-user':
        this.registerUser(this._keycloak.login.bind(this._keycloak));
        break;
      case 'estate-agent':
        this.registerUser(this.handleEstateAgentRegistration.bind(this));
        break;
      case 'urban-planner':
        this.registerUser(this.handleUrbanPlannerRegistration.bind(this));
        break;
      default:
        this._notificationService.showErrorMessage('Error', 'No user type selected.');
    }
  }

  private async registerUser(successCallback: (result: any) => void): Promise<void> {
    this.loading = true;
    let userRegistrationForm: UserDto = { ...this.userForm };
    this._authService.apiV1UserManagementAddUserPost({
      body: userRegistrationForm
    }).subscribe({
      next: (result: any) => {
        if (result.success) {
          successCallback(result);
        } else {
          this.handleRegistrationError(result.errorMessage);
        }
        this.loading = false;
      },
      error: (_error) => {
        this._notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
        this.loading = false;
      }
    });
  }

  private handleRegistrationError(errorMessage: string): void {
    if (errorMessage.includes('Duplicate entry')) {
      this._notificationService.showErrorMessage('Error', 'Duplicate entry. The user already exists.');
    } else {
      this._notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
    }
  }

  private handleEstateAgentRegistration(result: any): void {
    if (this.isPrincipal) {
      this._estateAgentRegistrationStateService.setIsPrincipal(true);
      this._estateAgentRegistrationStateService.setPrincipalUserId(result.userId);
    } else {
      this._estateAgentRegistrationStateService.setIsPrincipal(false);
      this._estateAgentRegistrationStateService.setPrincipalUserId(this.inviteId);
    }
    this._estateAgentRegistrationStateService.setUserId(result.userId);
    this.proceed();
  }

  private handleUrbanPlannerRegistration(result: any): void {
    this._urbanPlannerRegistrationStateService.setUserId(result.userId);
    this.proceed();
  }

  private proceed(): void {
    this._route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };
      this._router.navigate(['introduction-step'], navigationExtras);
    });
  }
}
