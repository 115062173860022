import { ListingLifeCycleState } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SideMenuItem {
  key?: number;
  value?: string;
  description?: string;
  icon?: string;
  state?: any;
}

@Component({
  selector: 'app-admin-side-menu',
  templateUrl: './admin-side-menu.component.html',
  styleUrls: ['./admin-side-menu.component.scss']
})
export class AdminSideMenuComponent implements OnInit {

  @Input() menuType: string;
  @Output() sideMenuChange = new EventEmitter<SideMenuItem>();

  menuIndex: number = 1;

  listingMenuItems: Array<SideMenuItem> = [
    {
      key: 1,
      value: 'My Tasks',
      description: "The task list is all the listed properties currently assigned to you.",
      icon: "assignment",
      state: ListingLifeCycleState.Assigned
    },
    {
      key: 2,
      value: 'Manage Listings',
      description: "The manage list is all the properties you are over-viewing.",
      icon: "edit",
      state: ListingLifeCycleState.Managed
    },
    {
      key: 3,
      value: 'Published Listings',
      description: "The published list is all properties currently for sale under “Property for Sale”.",
      icon: "cloud_upload",
      state: ListingLifeCycleState.Published
    },
    {
      key: 4,
      value: 'Under Offer',
      description: "The under offer list is all the properties that received an offer to purchase.",
      icon: "sell",
      state: ListingLifeCycleState.UnderOffer
    },
    {
      key: 5,
      value: 'Sold',
      description: "The sold list is all the properties that have been sold.",
      icon: "attach_money",
      state: ListingLifeCycleState.Sold
    },
    {
      key: 6,
      value: 'Archived',
      description: "This is all the properties that has not been processed for publication or that has an expired exclusive mandate.",
      icon: "archive",
      state: ListingLifeCycleState.Archived
    }
  ];

  profileMenuItems: Array<SideMenuItem> = [
    {
      key: 1,
      value: 'My Profile',
      icon: "person"
    },
    {
      key: 2,
      value: 'Company',
      icon: "business_center"
    },
    {
      key: 3,
      value: 'Forms And Documents',
      icon: "attach_file"
    },
    {
      key: 4,
      value: 'Subscriptions',
      icon: "subscriptions"
    },
    {
      key: 5,
      value: 'Favourites',
      icon: "grade"
    },
    {
      key: 6,
      value: 'Wallet',
      icon: "account_balance_wallet"
    },
    {
      key: 7,
      value: 'Calendar',
      icon: "calendar_today"
    },
    {
      key: 8,
      value: 'Ratings',
      icon: "signal_cellular_alt"
    },
    {
      key: 9,
      value: 'Notes',
      icon: "library_books"
    }
  ];

  constructor() { }

  ngOnInit(): void {
    const items = this.menuType === 'listings' ? this.listingMenuItems : this.profileMenuItems;
    const defaultItem = items.find(item => item.key === this.menuIndex);

    if (defaultItem) {
      this.sideMenuChange.emit({ value: defaultItem.value, description: defaultItem.description, state: defaultItem.state });
    }
  }

  onSideMenuChange(item: number): void {
    this.menuIndex = item;
    const items = this.menuType === 'listings' ? this.listingMenuItems : this.profileMenuItems;
    const selectedItem: SideMenuItem = items.find(itemKey => itemKey.key === item);

    if (selectedItem) {
      this.sideMenuChange.emit(selectedItem);
    }
  }
}
