<mat-card class="main-container">
    <li class="side-menu-item"
        *ngFor="let item of menuType === 'listings' ? listingMenuItems : menuType === 'profile' ? profileMenuItems : []">
        <button class="side-menu-button" [ngClass]="{'selected-menu-item': item.key == menuIndex}"
            (click)="onSideMenuChange(item.key)">
            <mat-icon class="material-icons-outlined link icon-circle" [matTooltip]="item.value"
                matTooltipPosition="right" [matTooltipShowDelay]="1000">
                {{ item.icon }}
            </mat-icon>
        </button>
    </li>
</mat-card>