<main class="dialog-container">
    <button class="close-button" (click)="closeDialog()">
        <mat-icon class="close-button-icon">close</mat-icon>
    </button>

    <div class="row paragraph mt-3">
        <h4 class="dialog-heading">Add Owner (Natural Person)</h4>
    </div>

    <div mat-dialog-content>
        <form [formGroup]="naturalPersonForm" (ngSubmit)="onSubmit()">

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="fullNames">Full names:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="fullNames" formControlName="fullNames" type="text"
                        maxlength="250">
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="surname">Surname:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="surname" formControlName="surname" type="text"
                        maxlength="250">
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="mobileNumber">Mobile:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="mobileNumber" formControlName="mobileNumber" type="text"
                        [ngClass]="{
                            'invalid-input':
                                naturalPersonForm.get('mobileNumber').invalid &&
                                naturalPersonForm.get('mobileNumber').touched
                            }">
                    <small class="burnt-red" *ngIf="
                            naturalPersonForm.get('mobileNumber').invalid &&
                            naturalPersonForm.get('mobileNumber').touched">
                        * Mobile number must contain exactly 10 digits.
                    </small>
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="emailAddress">Email:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="emailAddress" formControlName="emailAddress" type="text"
                        [ngClass]="{
                            'invalid-input':
                                naturalPersonForm.get('emailAddress').invalid &&
                                naturalPersonForm.get('emailAddress').touched
                            }">
                    <small class="burnt-red" *ngIf="
                            naturalPersonForm.get('emailAddress').invalid &&
                            naturalPersonForm.get('emailAddress').touched">
                        * Invalid email address.
                    </small>
                </div>
            </div>

            <div class="row paragraph mt-3">
                <div class="col-3">
                    <label for="confirmEmail">Confirm email:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="confirmEmail" formControlName="confirmEmail" type="text"
                        [ngClass]="{
                            'invalid-input':
                                naturalPersonForm.get('confirmEmail').invalid &&
                                naturalPersonForm.get('confirmEmail').touched
                            }">
                    <small class="burnt-red" *ngIf="
                            naturalPersonForm.get('confirmEmail').invalid &&
                            naturalPersonForm.get('confirmEmail').touched">
                        * Invalid email address.
                    </small>
                    <small class="burnt-red" *ngIf="
                            naturalPersonForm.hasError('emailMismatch') &&
                            naturalPersonForm.get('confirmEmail').touched">
                        Email addresses must match.
                    </small>
                </div>
            </div>

            <div class="row paragraph mt-3">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    Is this owner a resident of the Republic of South Africa?
                </label>
                <mat-radio-group formControlName="isRsaResident">
                    <mat-radio-button class="mt-1" [value]="true">Yes</mat-radio-button>
                    <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="isRsaResident === true" class="row paragraph mt-3">
                <div class="col-3">
                    <label for="idNumber">Identity number:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="idNumber" formControlName="idNumber" type="text"
                        [ngClass]="{'invalid-input':
                            naturalPersonForm.get('idNumber').invalid &&
                            naturalPersonForm.get('idNumber').touched}">
                    <small class="burnt-red" *ngIf="
                            naturalPersonForm.get('idNumber').invalid &&
                            naturalPersonForm.get('idNumber').touched">
                        * Identity number must contain exactly 13 digits.
                    </small>
                </div>
            </div>

            <div *ngIf="isRsaResident === false" class="row paragraph mt-3">
                <div class="col-3">
                    <label for="passportNumber">Passport number:</label>
                </div>
                <div class="col-9">
                    <input required class="default-input" id="passportNumber" formControlName="passportNumber"
                        type="text" [ngClass]="{'invalid-input':
                            naturalPersonForm.get('passportNumber').invalid &&
                            naturalPersonForm.get('passportNumber').touched}">
                    <small class="burnt-red" *ngIf="
                            naturalPersonForm.get('passportNumber').invalid &&
                            naturalPersonForm.get('passportNumber').touched">
                        * Passport number must start with a capital letter and be exactly 9 characters long.
                    </small>
                </div>
            </div>

            <div class="row paragraph mt-3">
                <label class="bold mb-2">
                    <span class="burnt-red">|</span>
                    Marital status of this co-owner?
                </label>
                <mat-radio-group formControlName="maritalStatus" (change)="onMaritalStatusChange($event)">
                    <div *ngFor="let status of maritalStatuses">
                        <mat-radio-button class="mt-1" [value]="status">
                            {{ status.value }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>

            <div *ngIf="selectedMaritalStatus?.intValue === 3" formGroupName="spouseDetail">
                <div class="row paragraph mt-3">
                    <label class="bold">Details of spouse</label>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spouseName">Name:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spouseName" formControlName="fullNames" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spouseSurname">Surname:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spouseSurname" formControlName="surname" type="text"
                            maxlength="250">
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spouseMobile">Mobile:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spouseMobile" formControlName="mobileNumber"
                            type="text" [ngClass]="{
                                'invalid-input':
                                    naturalPersonForm.get('spouseDetail.mobileNumber').invalid &&
                                    naturalPersonForm.get('spouseDetail.mobileNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                    naturalPersonForm.get('spouseDetail.mobileNumber').invalid &&
                                    naturalPersonForm.get('spouseDetail.mobileNumber').touched">
                            * Mobile number must contain exactly 10 digits.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spouseEmail">Email:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spouseEmail" formControlName="emailAddress"
                            type="text" [ngClass]="{
                                'invalid-input':
                                    naturalPersonForm.get('spouseDetail.emailAddress').invalid &&
                                    naturalPersonForm.get('spouseDetail.emailAddress').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                naturalPersonForm.get('spouseDetail.emailAddress').invalid &&
                                naturalPersonForm.get('spouseDetail.emailAddress').touched">
                            * Invalid email address.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spouseConfirmEmail">Confirm email:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spouseConfirmEmail" formControlName="confirmEmail"
                            type="text" [ngClass]="{
                                'invalid-input':
                                    naturalPersonForm.get('spouseDetail.confirmEmail').invalid &&
                                    naturalPersonForm.get('spouseDetail.confirmEmail').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                naturalPersonForm.get('spouseDetail.confirmEmail').invalid &&
                                naturalPersonForm.get('spouseDetail.confirmEmail').touched">
                            * Invalid email address.
                        </small>
                        <small class="burnt-red" *ngIf="
                                naturalPersonForm.get('spouseDetail').hasError('emailMismatch') &&
                                naturalPersonForm.get('spouseDetail.confirmEmail').touched">
                            Email addresses must match.
                        </small>
                    </div>
                </div>

                <div class="row paragraph mt-3">
                    <label class="bold mb-2">
                        <span class="burnt-red">|</span>
                        Is the owners' spouse a resident of the Republic of South Africa?
                    </label>
                    <mat-radio-group formControlName="isSpouseRsaResident">
                        <mat-radio-button class="mt-1" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div *ngIf="isSpouseRsaResident === true" class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spouseIdentityNumber">Identity number:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spouseIdentityNumber" formControlName="idNumber"
                            type="text" [ngClass]="{
                                'invalid-input':
                                    naturalPersonForm.get('spouseDetail.idNumber').invalid &&
                                    naturalPersonForm.get('spouseDetail.idNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                naturalPersonForm.get('spouseDetail.idNumber').invalid &&
                                naturalPersonForm.get('spouseDetail.idNumber').touched">
                            * Identity number must contain exactly 13 digits.
                        </small>
                    </div>
                </div>

                <div *ngIf="isSpouseRsaResident === false" class="row paragraph mt-3">
                    <div class="col-3">
                        <label for="spousePassportNumber">Passport number:</label>
                    </div>
                    <div class="col-9">
                        <input required class="default-input" id="spousePassportNumber" formControlName="passportNumber"
                            type="text" [ngClass]="{
                                'invalid-input':
                                    naturalPersonForm.get('spouseDetail.passportNumber').invalid &&
                                    naturalPersonForm.get('spouseDetail.passportNumber').touched
                                }">
                        <small class="burnt-red" *ngIf="
                                naturalPersonForm.get('spouseDetail.passportNumber').invalid &&
                                naturalPersonForm.get('spouseDetail.passportNumber').touched">
                            * Passport number must start with a capital letter and be exactly 9 characters long.
                        </small>
                    </div>
                </div>
            </div>

            <div class="row paragraph mt-5">
                <div class="col-12">
                    <u class="bold">Please confirm</u>
                </div>
                <div class="col-12 custom-checkbox-container">
                    <input type="checkbox" id="hasOwnerConsent" formControlName="confirmedConsent"
                        class="custom-checkbox">
                    <label for="hasOwnerConsent" class="custom-checkbox-label">
                        I have consent from this owner to fill in this form and to provide Property Matrix with
                        information on their behalf. I can confirm that this owner agrees and give permission to sell
                        this property.
                    </label>
                </div>
            </div>

            <div class="row paragraph mt-5">
                <div class="text-center">
                    <button class="button" type="submit" [disabled]="naturalPersonForm.invalid">Submit</button>
                </div>
            </div>
        </form>
    </div>
</main>