import { ContractDetailDto, JuristicPersonDetailDto, OwnershipDetailDto, PersonalDetailDto, PropertyDetailDto, PropertyOwnershipTypeDto, SellMyPropertyDocumentDto, SellMyPropertyDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyListingStateService {

  private formState = new BehaviorSubject<SellMyPropertyDto>({
    ownershipDetail: {
      ownershipPersonalDetail: [],
      juristicPersonDetail: []
    },
    propertyDetails: null,
    contractDetail: {
      conveyancerDetail: {
        companyName: '',
        contactPerson: '',
        emailAddress: '',
        mobileNumber: '',
        officeNumber: '',
      },
      urbanPlannerDetail: {
        city: '',
        company: '',
        email: '',
        name: '',
        phone: '',
        surname: ''
      },
    },
    documents: []
  });

  formState$ = this.formState.asObservable();

  constructor() { }

  updateOwnershipDetails(ownershipDetails: OwnershipDetailDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: ownershipDetails
    });
  }

  updatePropertyOwnershipType(propertyOwnershipType: PropertyOwnershipTypeDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: {
        ...currentState.ownershipDetail,
        propertyOwnershipType: propertyOwnershipType
      }
    });
  }

  updateNaturalPersonDetails(naturalPersons: PersonalDetailDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: {
        ...currentState.ownershipDetail,
        ownershipPersonalDetail: naturalPersons
      }
    });
  }

  updateJuristicPersonDetails(juristicPersons: JuristicPersonDetailDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: {
        ...currentState.ownershipDetail,
        juristicPersonDetail: juristicPersons
      }
    });
  }

  updatePropertyDetails(propertyDetails: PropertyDetailDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      propertyDetails: propertyDetails
    });
  }

  updateContractDetails(contractDetails: ContractDetailDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      contractDetail: contractDetails
    });
  }

  updateDocuments(documents: SellMyPropertyDocumentDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      documents: documents
    });
  }

  updateDocument(index: number, document: SellMyPropertyDocumentDto) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = document;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments
    });
  }

  removeDocument(index: number) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = null;
    // updatedDocuments.splice(index, 1);
    this.formState.next({
      ...currentState,
      documents: updatedDocuments
    });
  }

  getFormState(): SellMyPropertyDto {
    return this.formState.value;
  }
}
