<main class="main-container">
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="row paragraph p-2">
        <div class="col-1" *ngIf="isSideMenuVisible">
            <app-admin-side-menu [menuType]="menuType" (sideMenuChange)="onSideMenuChange($event)">
            </app-admin-side-menu>
        </div>
        <div [class.col-11]="isSideMenuVisible" [class.col-12]="!isSideMenuVisible">
            <div class="heading-container">
                <button class="center-content toggle-side-menu-button" (click)="toggleSideMenu()">
                    <mat-icon class="circle-button">
                        {{ isSideMenuVisible ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}
                    </mat-icon>
                </button>
                <div class="p-2 mt-1">
                    <h5 class="heading-5">{{ currentHeading }}</h5>
                </div>
            </div>
            <div class="pt-2 pb-2">
                {{ currentDescription }}
            </div>
            <div *ngIf="currentHeading === 'My Profile'">
                Coming Soon!
            </div>
            <div *ngIf="currentHeading === 'Company'">
                <app-admin-profile-company></app-admin-profile-company>
            </div>
        </div>
    </div>
</main>