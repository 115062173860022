<main class="main-container">
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="row paragraph p-2">
        <div class="col-1" *ngIf="isSideMenuVisible">
            <app-admin-side-menu [menuType]="menuType" (sideMenuChange)="onSideMenuChange($event)">
            </app-admin-side-menu>
        </div>
        <div [class.col-11]="isSideMenuVisible" [class.col-12]="!isSideMenuVisible">
            <div class="heading-container">
                <button class="center-content toggle-side-menu-button" (click)="toggleSideMenu()">
                    <mat-icon class="circle-button">
                        {{ isSideMenuVisible ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}
                    </mat-icon>
                </button>
                <div class="p-2 mt-1">
                    {{ currentHeading }}
                </div>
            </div>
            <div class="pt-2 pb-2">
                {{ currentDescription }}
            </div>
            <div class="row pt-1 pb-1">
                <div class="create-search-container" *ngIf="isCreateGroupButtonVisible()">
                    <button class="paragraph center-content create-group-button" (click)="onCreateGroup()">
                        <mat-icon class="add-icon">add</mat-icon>
                        <span class="create-group-label">Create Group</span>
                    </button>
                    <div class="search-wrapper col-3">
                        <mat-icon class="search-icon">search</mat-icon>
                        <input type="text" placeholder="Search" class="search-input" />
                    </div>
                </div>
            </div>
            <mat-tab-group class="report-type-tab-group" [selectedIndex]="reportTypeTabNumber - 1"
                (selectedIndexChange)="navigateToReportTypeTab($event + 1)">
                <mat-tab *ngFor="let type of reportTypes">
                    <ng-template mat-tab-label>
                        <mat-icon class="material-icons-outlined tab-icon">
                            {{ type.icon }}
                        </mat-icon>
                        <span class="tab-title">
                            {{ type.value }}
                        </span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <div *ngIf="reportTypeTabNumber === 1" class="single-container">
                <div class="card-container row">
                    <div class="listing-card col-12" *ngFor="let listing of filteredListings; let i = index">
                        <app-listing-card [listing]="listing" [users]="users" [currentUserIndex]="getUserIndex(i)"
                            (listingClicked)="goToListingOverview(listing)"
                            (listingCardSelected)="onListingCardSelected($event)">
                        </app-listing-card>
                    </div>
                </div>
            </div>
            <div *ngIf="reportTypeTabNumber === 2">
                <div *ngFor="let group of propertyListingGroups | keyvalue">
                    <mat-card class="group-container">
                        <div class="group-heading">
                            <span class="paragraph">
                                {{ group.value?.groupName ?? 'Group Name Not Available' }}
                            </span>
                        </div>
                        <div class="card-container">
                            <div class="group-card">
                                <app-group-card [group]="group" (groupClicked)="goToListingGroupOverview(group)">
                                </app-group-card>
                            </div>
                            <div *ngFor="let listing of group?.value?.propertyListings; let i = index"
                                class="listing-card">
                                <app-listing-card [listing]="listing" [users]="users">
                                </app-listing-card>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</main>