import { ListingGroupStateService } from 'src/app/shared/services/listing-group-state-service/listing-group-state.service';
import { MultiplePropertyListingDto, PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { ListingStateService } from 'src/app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { KeyValueItem } from 'src/app/common/global-models/key-value-item';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-overview',
  templateUrl: './listing-overview.component.html',
  styleUrls: ['./listing-overview.component.scss']
})
export class ListingOverviewComponent implements OnInit {

  selectedListing: PropertyListingDto;
  selectedListingGroup: MultiplePropertyListingDto;

  tabNumber: number = 1;
  listingId: string | null = null;
  createdDate: string | null = null;
  listingType: string | null = null;
  listingAddress: string | null = null;
  planningReportId: string | null = null;

  overviewItems: KeyValueItem[] = [
    { key: 1, value: 'Tasks', icon: "assignment" },
    { key: 2, value: 'Under Offer', icon: "request_quote" },
    { key: 3, value: 'Users', icon: "person" },
    { key: 4, value: 'Documents', icon: "description" },
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private listingStateService: ListingStateService,
    private notificationService: NotificationService,
    private listingGroupStateService: ListingGroupStateService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
      this.listingId = params['listingId'];

      const tabParam = params['tab'];
      if (tabParam) {
        const tabIndex = parseInt(tabParam, 10);
        if (tabIndex >= 1 && tabIndex <= this.overviewItems.length) {
          this.tabNumber = tabIndex;
        }
      }

      if (this.listingId) {
        this.listingStateService.loadPropertyListingById(this.listingId);
      }

      if (this.listingType === 'single') {
        this.listingStateService.selectedListing$.subscribe((listing: PropertyListingDto) => {
          this.selectedListing = listing;
          if (listing) {
            this.planningReportId = listing.planningReportId;
            this.listingAddress = listing.addressTitle;
            this.createdDate = listing.createdDate;
          }
        });
      } else if (this.listingType === 'multiple') {
        this.listingGroupStateService.selectedListingGroup$.subscribe((group: any) => {
          this.selectedListingGroup = group.value;
          if (group.value) {
            this.planningReportId = group.value.planningReportId;
            this.listingAddress = group.value.groupName;
            this.createdDate = group.value.createdDate;
          }
        });
      } else {
        this.notificationService.showErrorMessage('Error', 'The listing type is undefined.');
      }
    });
  }

  goToAdminListings() {
    this.router.navigate(['admin/listings']);
  }

  selectTab(index: number) {
    const selectedItem = this.overviewItems[index];
    if (selectedItem) {
      this.tabNumber = selectedItem.key;

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tab: this.tabNumber },
        queryParamsHandling: 'merge'
      });
    }
  }
}
