import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

import { UnitSelectionDialogComponent } from './unit-selection-dialog/unit-selection-dialog.component';

declare var RiskscapeMap: any;
declare var RiskscapeSearch: any;

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit {

  @Output() selectedProperty: EventEmitter<any> = new EventEmitter();
  @Output() selectedUnitNumber: EventEmitter<any> = new EventEmitter();
  @Output() scriptLoadStatus: EventEmitter<string> = new EventEmitter();

  private map: any;
  private search: any;
  private apiKey: string = environment.riskscapeApiKey;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadStyles();
    this.loadScripts().then(() => {
      this.initMapAndSearch();
    }).catch(_error => {
      this.scriptLoadStatus.emit('Please refresh the page or try again later.');
    });
  }

  private loadStyles(): void {
    const stylesheets = [
      'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css',
      'https://unpkg.com/riskscape-search@0.8.0/dist/search.css'
    ];

    stylesheets.forEach(href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
    });
  }

  private loadScripts(): Promise<void[]> {
    const scripts = [
      'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js',
      'https://unpkg.com/riskscape-map@0.8.0/dist/map.umd.js',
      'https://unpkg.com/riskscape-search@0.8.0/dist/search.umd.js'
    ];

    return Promise.all(scripts.map(src => this.loadScript(src)));
  }

  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject();
      document.body.appendChild(script);
    });
  }

  private initMapAndSearch(): void {
    const mapOptions = JSON.parse(localStorage.getItem('map')) || { maxZoom: 18 };

    this.map = new RiskscapeMap({
      key: this.apiKey,
      element: 'map',
      options: mapOptions
    });

    this.search = new RiskscapeSearch({
      key: this.apiKey,
      element: 'search'
    });

    this.map.bindSearch(this.search);

    this.map.on('zoomend', () => {
      const data = {
        zoom: this.map.getZoom(),
        center: this.map.getCenter()
      };
      localStorage.setItem('map', JSON.stringify(data));
    });

    this.map.on('click', (event: any) => {
      if (event.originalEvent.ctrlKey) {
        this.map.selectFeature('property_scheme', event.latlng);
      }
    });

    this.map.on('feature:selected', async ({ feature }: any) => {
      const hasUnits = feature?.has_sectional_units;

      if (hasUnits) {
        let units = feature.type === 'scheme'
          ? await this.map.getSchemeUnits(feature.scheme_id)
          : await this.map.getPropertyUnits(feature.property_key);

        if (feature.type === 'property') {
          units = units.map((unit: any) => {
            return { unit_number: unit, property_key: feature.property_key };
          });
        }

        this.openUnitSelectionDialog(units);

        return;
      }

      if (!feature?.property_key || !feature?.unit_number) {
        return;
      }

      this.showConfirmProperty(feature.property_key, feature.unit_number);
    });
  }

  private openUnitSelectionDialog(units: any[]): void {
    const dialogRef = this.dialog.open(UnitSelectionDialogComponent, {
      width: '20vw',
      data: { units }
    });

    dialogRef.afterClosed().subscribe((selectedUnit: any) => {
      if (selectedUnit) {
        this.showConfirmProperty(selectedUnit.property_key, selectedUnit.unit_number);
      }
    });
  }

  private showConfirmProperty(property_key: string, unit_number: string): void {
    this.map.getProperty(property_key, unit_number).then((data: any) => {
      this.selectedProperty.emit(data);
    });
  }
}
