<div class="loading" *ngIf="loading == true">Loading&#8230;</div>

<main class="main-container" id="main">
    <div class="content">
        <div class="container">
            <app-progress-tracker [steps]="steps" [currentStep]="pageNumber - 1" (stepClick)="selectPage($event)">
            </app-progress-tracker>
            <section class="section-container">
                <div [hidden]="pageNumber != 1">
                    <app-listing-ownership-details (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()">
                    </app-listing-ownership-details>
                </div>
                <div [hidden]="pageNumber != 2">
                    <app-listing-property-details (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()">
                    </app-listing-property-details>
                </div>
                <div [hidden]="pageNumber != 3">
                    <app-listing-contract-details (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()">
                    </app-listing-contract-details>
                </div>
                <div [hidden]="pageNumber != 4">
                    <app-listing-upload-documents (pageNumberEvent)="changePage($event)"
                        (formSubmittedEvent)="onFormSubmitted()" (documentsSubmitted)="onDocumentSubmitted()">
                    </app-listing-upload-documents>
                </div>
            </section>
        </div>
    </div>
</main>