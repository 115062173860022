import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-dialog',
  templateUrl: './benefits-dialog.component.html',
  styleUrls: ['./benefits-dialog.component.scss']
})
export class BenefitsDialogComponent implements OnInit {

  constructor(public _dialogRef: MatDialogRef<BenefitsDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
