import { PropertyListingDto, ListingLifeCycleState, MultiplePropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { ListingGroupStateService } from 'src/app/shared/services/listing-group-state-service/listing-group-state.service';
import { ListingStateService } from 'src/app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyListingService } from 'src/app/services/property-matrixV2/services';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { GlobalMethods } from 'src/app/common/global-methods';
import { NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

import { CreateGroupComponent } from './create-group/create-group.component';

@Component({
  selector: 'app-admin-listings',
  templateUrl: './admin-listings.component.html',
  styleUrls: ['./admin-listings.component.scss', '../../../../css/2-modules/_admin-portal.scss']
})
export class AdminListingsComponent implements OnInit {

  propertyListings: PropertyListingDto[];
  propertyListingGroups: MultiplePropertyListingDto[];
  filteredListings: PropertyListingDto[];

  isSideMenuVisible: boolean = true;
  currentHeading: string = '';
  currentDescription: string = '';
  reportTypeIndex: number = 1;
  currentUserIndex: number = 1;
  selectedListingIds: string[] = [];
  groupName: string;
  reportReferenceNumber: string;
  menuType: string = 'listings';
  singleListing: string = 'single';
  multipleListing: string = 'multiple';
  reportTypeTabNumber: number = 1;

  loading: boolean = false;

  reportTypes: KeyValuePair[] = [
    { key: 1, value: 'Single', icon: "home" },
    { key: 2, value: 'Multiple', icon: "holiday_village" }
  ];

  users = [
    { key: 1, name: 'Reinier Minny', role: 'Senior Planner', roleInitial: 'P', roleColor: '#E5277B' },
    { key: 2, name: 'Storm Vezasie', role: 'Junior Planner', roleInitial: 'J', roleColor: '#E98642' },
    { key: 3, name: 'Karen Minny', role: 'Senior Agent', roleInitial: 'A', roleColor: '#61C8D5' },
    { key: 4, name: 'Amelia Amani', role: 'Network Agent', roleInitial: 'A', roleColor: '#51583D' },
    { key: 5, name: 'Silvia Ankievicz', role: 'Network Planner', roleInitial: 'P', roleColor: '#5AA697' }
  ];

  constructor(
    private router: Router,
    private listingService: PropertyListingService,
    private listingStateService: ListingStateService,
    private listingGroupStateService: ListingGroupStateService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
    this.getPropertyListings();
    this.getMultiplePropertyListings();
  }

  toggleSideMenu() {
    this.isSideMenuVisible = !this.isSideMenuVisible;
  }

  onSideMenuChange(change: { heading: string, description: string, state: ListingLifeCycleState }): void {
    GlobalMethods.scrollToTop();
    this.currentHeading = change.heading;
    this.currentDescription = change.description;
    this.filterListingsByState(change.state);
  }

  onReportTypeChange(item: number): void {
    this.reportTypeIndex = item;
  }

  getPropertyListings(): void {
    this.loading = true;
    this.listingService.apiV1PropertyListingGetAllPropertyListingsGet().subscribe({
      next: (result: PropertyListingDto[]) => {
        this.propertyListings = result;
        this.filterListingsByState(ListingLifeCycleState.Assigned); // Default filter to My Tasks
        this.loading = false;
      },
      error: (_error) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not load property listings.');
      }
    });
  }

  getMultiplePropertyListings(): void {
    this.loading = true;
    this.listingService.apiV1PropertyListingGetAllMultiplePropertyListingsGet().subscribe({
      next: (result: MultiplePropertyListingDto[]) => {
        this.propertyListingGroups = result;
        this.loading = false;
      },
      error: (_error) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not load property listing groups.');
      }
    });
  }

  onListingCardSelected(listingId: string) {
    if (!this.selectedListingIds.includes(listingId)) {
      this.selectedListingIds.push(listingId);
    } else {
      const index = this.selectedListingIds.indexOf(listingId);
      if (index > -1) {
        this.selectedListingIds.splice(index, 1);
      }
    }
  }

  onCreateGroup() {
    const dialogRef = this.dialog.open(CreateGroupComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.groupName = result.groupName;
        this.saveMultiplePropertyListing();
      }
    });
  }

  isCreateGroupButtonVisible() {
    return this.selectedListingIds?.length > 0;
  }

  get propertyListingGroupToSave(): MultiplePropertyListingDto {
    return {
      propertyListingIds: this.selectedListingIds,
      groupName: this.groupName,
      coverImage: this.propertyListingGroups[0]?.coverImage,
      lifeCycleState: ListingLifeCycleState.Assigned,
      reportReferenceNumber: this.reportReferenceNumber,
      currentUser: this.users[this.currentUserIndex - 1],
      createdDate: new Date().toISOString(),
    };
  }

  saveMultiplePropertyListing(): void {
    this.listingService.apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost({
      body: this.propertyListingGroupToSave
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'Successfully saved property group details.');
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Could not save property group details.');
      }
    });
  }

  goToListingOverview(listing: PropertyListingDto): void {
    this.listingStateService.setSelectedListing(listing);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'listingType': this.singleListing
      }
    };
    this.router.navigate(['admin/listings/listing-overview'], navigationExtras);
  }

  goToListingGroupOverview(group: MultiplePropertyListingDto): void {
    this.listingGroupStateService.setSelectedListingGroup(group);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'listingType': this.multipleListing
      }
    };
    this.router.navigate(['admin/listings/listing-overview'], navigationExtras);
  }

  getUserIndex(listingIndex: number): number {
    return (listingIndex % this.users.length) + 1;
  }

  navigateToReportTypeTab(index: number) {
    this.reportTypeTabNumber = index;
    GlobalMethods.scrollToTop();
  }

  filterListingsByState(state: ListingLifeCycleState): void {
    this.filteredListings = this.propertyListings.filter(listing => listing.lifeCycleState === state);
  }
}
