<main class="dialog-container">
    <button class="close-button" (click)="closeDialog()">
        <mat-icon class="close-button-icon">close</mat-icon>
    </button>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <h4 class="dialog-heading">
                Required if advertising joint properties as one development opportunity
            </h4>
        </div>
    </div>
    <div mat-dialog-content>
        <ol class="list-items paragraph light">
            <li>
                A map or aerial photo, indicating all the properties involved and the exact boundaries.
            </li>
            <li>
                A Joint Sales Agreement, setting out the rules and general structure that the group of sellers will
                adhere to. The Property Matrix legal team can assist in this matter.
            </li>
            <li>
                A resolution, signed by all owners, nominating a single representative (co-seller) who will liaise with
                Property Matrix.
            </li>
            <li>
                A resolution, signed by all owners, nominating a single conveyancer, experienced in acting for groups.
                Our legal team specialises in these transactions.
            </li>
            <li>
                A resolution, signed by all owners, nominating a single commercial broker/estate agent registered with
                Property Matrix, who will act on behalf of all other brokers/agents involved.
            </li>
            <li>
                A Commission Split Agreement, drafted by the nominated agent and signed by all agents, owners, and the
                nominated conveyancer.
            </li>
        </ol>
        <div class="col-12 heading-5-light">
            Please note:
        </div>
        <div class="paragraph light">
            Each owner/seller involved will sign the sole and exclusive mandate contract, indicating the collective
            asking price as agreed upon. It is required that the Joint Sales Agreement (as an annexure to the contract),
            indicates the percentage split of the selling price between all parties once an offer is received. Property
            Matrix can assist in this matter: we have a national panel of SACPVP-registered valuers in our network and
            can refer a professional valuer who will objectively assess the open market value of each property involved.
        </div>
        <button class="button-light heading-3-light" (click)="closeDialog()">
            GOT IT
        </button>
    </div>
</main>