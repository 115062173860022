import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { ReportDocumentDto } from 'src/app/services/property-matrixV2/models';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listing-documents',
  templateUrl: './listing-documents.component.html',
  styleUrls: ['./listing-documents.component.scss'],
  providers: [DatePipe]
})
export class ListingDocumentsComponent implements OnInit {

  @Input() planningReportId: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loading: boolean = false;
  documents: ReportDocumentDto[] = [];
  displayedColumns: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];

  dataSource = new MatTableDataSource(this.documents);

  constructor(
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs to be exported before documents will be available here.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportDocumentsGet({
        planningReportId: this.planningReportId
      }).subscribe({
        next: (documents: ReportDocumentDto[]) => {
          this.documents = documents;
          this.dataSource.data = this.documents;
          this.dataSource.sort = this.sort;
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load report documents.');
          this.loading = false;
        }
      });
    }
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'd MMMM yyyy');
  }
}
