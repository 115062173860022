import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { EstateAgentContractAgreementDto } from 'src/app/services/property-matrixV2/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estate-agent-sign-contract',
  templateUrl: './estate-agent-sign-contract.component.html',
  styleUrls: ['./estate-agent-sign-contract.component.scss']
})
export class EstateAgentSignContractComponent implements OnInit {

  @Output() contractSigned = new EventEmitter<void>();

  isPrincipal: boolean = false;
  contractAgreementForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    this.isPrincipal = this._estateAgentRegistrationStateService.getFormState().isPrincipal;
    const currentContractAgreement = this._estateAgentRegistrationStateService.getFormState().contractAgreement || {};

    this.contractAgreementForm = this._formBuilder.group({
      isCollaborationAgreementProvided: new FormControl(currentContractAgreement.isCollaborationAgreementProvided || false),
      isCollaborationAgreementRead: new FormControl(currentContractAgreement.isCollaborationAgreementRead || false),
      isPledgeAccepted: new FormControl(currentContractAgreement.isPledgeAccepted || false),
    });

    this.updateFormValidators();
  }

  updateFormValidators() {
    if (this.isPrincipal) {
      this.contractAgreementForm.get('isCollaborationAgreementProvided')?.setValidators(Validators.requiredTrue);
      this.contractAgreementForm.get('isCollaborationAgreementRead')?.clearValidators();
      this.contractAgreementForm.get('isPledgeAccepted')?.clearValidators();
    } else {
      this.contractAgreementForm.get('isCollaborationAgreementProvided')?.clearValidators();
      this.contractAgreementForm.get('isCollaborationAgreementRead')?.setValidators(Validators.requiredTrue);
      this.contractAgreementForm.get('isPledgeAccepted')?.setValidators(Validators.requiredTrue);
    }

    this.contractAgreementForm.get('isCollaborationAgreementProvided')?.updateValueAndValidity();
    this.contractAgreementForm.get('isCollaborationAgreementRead')?.updateValueAndValidity();
    this.contractAgreementForm.get('isPledgeAccepted')?.updateValueAndValidity();
  }

  onSubmit() {
    if (this.contractAgreementForm.valid) {
      const formValue: EstateAgentContractAgreementDto = { ...this.contractAgreementForm.value };
      this._estateAgentRegistrationStateService.updateContractAgreement(formValue);
      this.contractSigned.emit();
    }
  }
}
