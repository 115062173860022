import { EstateAgentCompanyInformationDto, EstateAgentContractAgreementDto, EstateAgentDocumentDto, EstateAgentDto, EstateAgentPersonalInformationDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstateAgentRegistrationStateService {

  private formState = new BehaviorSubject<EstateAgentDto>({
    personalInformation: {
      otherSectors: null,
      sectors: null,
      status: null,
      yearsInOperation: 0
    },
    companyInformation: {
      accountHolderName: null,
      accountNumber: null,
      accountType: null,
      agencyBranch: null,
      agencyName: null,
      bankName: null,
      branchName: null,
      branchNumber: null,
      city: null,
      companyFidelityFundCertificateNumber: null,
      complexName: null,
      firmName: null,
      firmPinNumber: null,
      individualFidelityFundCertificateNumber: null,
      individualReferenceNumber: null,
      isAffiliatedWithAgency: false,
      principalIdNumber: null,
      principalName: null,
      principalSurname: null,
      streetName: null,
      streetNumber: null,
      suburb: null,
      tradingName: null,
      unitNumber: null,
      vatNumber: null
    },
    documents: [],
    contractAgreement: {
      isCollaborationAgreementProvided: false,
      isCollaborationAgreementRead: false,
      isPledgeAccepted: false
    },
    userId: localStorage.getItem('userId') || null,
    isPrincipal: localStorage.getItem('isPrincipal') === 'true',
    principalUserId: localStorage.getItem('principalUserId') || null
  });

  formState$ = this.formState.asObservable();

  setUserId(userId: string) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      userId: userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
    localStorage.setItem('userId', userId);
  }

  setPrincipalUserId(principalUserId: string) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: principalUserId
    });
    localStorage.setItem('principalUserId', principalUserId);
  }

  setIsPrincipal(isPrincipal: boolean) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      isPrincipal: isPrincipal,
      userId: currentState.userId,
      principalUserId: currentState.principalUserId
    });
    localStorage.setItem('isPrincipal', isPrincipal.toString());
  }

  updatePersonalInformation(personalInfo: EstateAgentPersonalInformationDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      personalInformation: personalInfo,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
  }

  updateCompanyInformation(companyInfo: EstateAgentCompanyInformationDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      companyInformation: companyInfo,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
  }

  updateDocuments(documents: EstateAgentDocumentDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      documents: documents,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
  }

  updateDocument(index: number, document: EstateAgentDocumentDto) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = document;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
  }

  removeDocument(index: number) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = null;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
  }

  updateContractAgreement(contractAgreement: EstateAgentContractAgreementDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      contractAgreement: contractAgreement,
      userId: currentState.userId,
      isPrincipal: currentState.isPrincipal,
      principalUserId: currentState.principalUserId
    });
  }

  getFormState(): EstateAgentDto {
    return this.formState.value;
  }
}
