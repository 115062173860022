import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.scss']
})
export class DisclaimerDialogComponent implements OnInit {

  constructor(public _dialogRef: MatDialogRef<DisclaimerDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
