<main class="main-container">
    <div class="content">
        <div class="row text-center">
            <div class="col-12">
                <h1 class="section-title">PROPERTY LISTING</h1>
                <p class="section-subtitle mt-4">Thank you for choosing <span class="bold">Property Matrix</span>.</p>
                <p class="section-description mt-4">
                    Completing this form should take 15-20 minutes in 4 easy steps:
                </p>
            </div>
        </div>
        <div class="row text-center steps-row mt-5">
            <div class="col-12 col-md-3 d-flex flex-column align-items-center step">
                <h3 class="step-title">STEP</h3>
                <h1 class="step-number">1</h1>
                <p class="step-description">Ownership<br>details</p>
            </div>
            <div class="col-12 col-md-3 d-flex flex-column align-items-center step">
                <h3 class="step-title">STEP</h3>
                <h1 class="step-number">2</h1>
                <p class="step-description">Property<br>information</p>
            </div>
            <div class="col-12 col-md-3 d-flex flex-column align-items-center step">
                <h3 class="step-title">STEP</h3>
                <h1 class="step-number">3</h1>
                <p class="step-description">Contract<br>details</p>
            </div>
            <div class="col-12 col-md-3 d-flex flex-column align-items-center step">
                <h3 class="step-title">STEP</h3>
                <h1 class="step-number">4</h1>
                <p class="step-description">Upload<br>documents</p>
            </div>
        </div>
        <div class="row text-center document-info mt-5">
            <div class="col-12">
                <p class="document-instructions-title">
                    For step 4, please have the following ready in PDF format:
                </p>
                <ul class="document-list">
                    <li>
                        <b>Mandate:</b> Sole and exclusive mandate indicating a fair market value as the asking price
                        and signed by duly authorised seller(s).
                    </li>
                    <li>
                        <b>SIF:</b> Site Information Form and pictures of the property: street view, interior, exterior.
                    </li>
                    <li>
                        <b>Instruction Letter:</b> If Seller(s) comply with FICA and verification documents provided as
                        proof.
                    </li>
                    <li>
                        <b>If natural person(s):</b> ID of seller(s) or passport (only if not a resident of RSA). If
                        married in community: copy of spouse's ID/passport.
                    </li>
                    <li>
                        <b>If juristic person (company/cc/NPO):</b> registration certificate. If a trust: letter of
                        authority.
                    </li>
                    <li>
                        <b>If multiple members:</b> resolution authorising the representative and ID/passport of the
                        representative.
                    </li>
                    <li>
                        <b>If property is in eThekwini:</b> a copy of the water & electricity bill.
                    </li>
                    <li>
                        <b>If multiple/joint properties:</b> a map, indicating all the adjacent properties which will
                        form part of the development opportunity.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row text-center mt-4">
            <div class="col-12">
                <button class="button" type="button" (click)="onContinue()">Continue</button>
            </div>
        </div>
    </div>
</main>