<main class="main-container">
    <mat-card class="table-card">
        <div class="card-header-section">
            <div class="left-content">
                <h4 class="heading-4">Network Agents</h4>
            </div>
            <div class="right-content">
                <button (click)="openInviteEstateAgentDialog()" class="center-content add-button">
                    <mat-icon class="add-icon">add</mat-icon>
                    <span class="paragraph">Add Agent</span>
                </button>
            </div>
        </div>
        <app-data-table [data]="data" [columns]="columns" [actionOptions]="actionOptions"></app-data-table>
    </mat-card>
</main>