import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-listing-overview',
  templateUrl: './property-listing-overview.component.html',
  styleUrls: ['./property-listing-overview.component.scss']
})
export class PropertyListingOverviewComponent implements OnInit {

  sellerType: string | null = null;
  route: string = '/sell-your-property/steps';

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.sellerType = params['sellerType'];
    });

    GlobalMethods.scrollToTop();
  }

  onContinue(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        sellerType: this.sellerType
      }
    };

    this._router.navigate([this.route], navigationExtras);
  }
}
