import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { CurrentConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';

@Component({
  selector: 'app-current-construction-scope',
  templateUrl: './current-construction-scope.component.html',
  styleUrls: ['./current-construction-scope.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class CurrentConstructionScopeComponent implements OnInit, OnChanges {

  @Input() planningReportId: string;
  @Input() potentialUsableProperty: number;

  @Output() currentConstructionScopeChange = new EventEmitter<CurrentConstructionScopeDto>();

  currentConstructionScopeData: CurrentConstructionScopeDto;
  squareMetersConversion: number = 10000;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadCurrentConstructionScope();
  }

  ngOnChanges(): void {
    this.calculateValues();
  }

  async loadCurrentConstructionScope(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetCurrentConstructionScopeGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: any) => {
        this.currentConstructionScopeData = response;
        this.calculateValues();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load current construction scope data.');
      }
    });
  }

  calculateValues(): void {
    if (this.currentConstructionScopeData) {
      this.currentConstructionScopeData.currentMaximumOpenSpaceRequired = Math.max(0, Math.floor(this.potentialUsableProperty * (this.currentConstructionScopeData.currentOpenSpaceRequired / 100) * 10000) / 10000);
      this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace = Math.max(0, Math.floor(this.potentialUsableProperty * this.currentConstructionScopeData.currentDensity));
      this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio = Math.max(0, Math.floor(this.potentialUsableProperty * this.currentConstructionScopeData.currentFloorAreaRatio * this.squareMetersConversion));
      this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, Math.floor(this.potentialUsableProperty * this.currentConstructionScopeData.currentHeight * (this.currentConstructionScopeData.currentCoverage / 100) * this.squareMetersConversion));
      this.currentConstructionScopeData.currentMaximumUnitsAverageSize = Math.max(0, Math.floor(this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight / this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace));

      this.currentConstructionScopeData.currentParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace * this.currentConstructionScopeData.currentParkingSpaces));
      this.currentConstructionScopeData.currentVisitorsParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentVisitorsParkingSpaces * this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace));
      this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio / 100 * this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMeters));
      this.currentConstructionScopeData.currentTotalParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.currentConstructionScopeData.currentParkingSpacesWithMaxRights + this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights));
      this.currentConstructionScopeData.currentParkingSpacesAvailableOnVacantLandWithMaxRights = Math.max(0, Math.floor((1 - (this.currentConstructionScopeData.currentCoverage / 100)) * ((this.potentialUsableProperty - this.currentConstructionScopeData.currentMaximumOpenSpaceRequired) * this.squareMetersConversion) / 23));

      this.currentConstructionScopeChange.emit(this.currentConstructionScopeData);
    }
  }
}
