import { ProfessionDto, UrbanPlannerExtendedNetworkDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-extended-professional-dialog',
  templateUrl: './add-edit-extended-professional-dialog.component.html',
  styleUrls: ['./add-edit-extended-professional-dialog.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AddEditExtendedProfessionalDialogComponent implements OnInit {

  professionalForm: FormGroup;
  professions: ProfessionDto[] = [];
  professional: UrbanPlannerExtendedNetworkDto;

  constructor(
    private _formBuilder: FormBuilder,
    private _lookupService: LookupService,
    private _notificationService: NotificationService,
    public dialogRef: MatDialogRef<AddEditExtendedProfessionalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UrbanPlannerExtendedNetworkDto
  ) { }

  ngOnInit(): void {
    this.getProfessions();

    this.professionalForm = this._formBuilder.group({
      name: [this.data?.name || '', [Validators.required, Validators.maxLength(250)]],
      surname: [this.data?.surname || '', [Validators.required, Validators.maxLength(250)]],
      profession: [null, Validators.required],
      companyName: [this.data?.companyName || '', [Validators.required, Validators.maxLength(250)]],
      mobile: [this.data?.mobile || '', [Validators.maxLength(10), CustomValidators.mobileValidator()]],
      email: [this.data?.email || '', [Validators.maxLength(250), CustomValidators.emailValidator()]],
      website: [this.data?.website || '', Validators.maxLength(250)]
    });
  }

  getProfessions(): void {
    this._lookupService.apiV1LookupGetProfessionsGet().subscribe({
      next: (professions: any) => {
        this.professions = professions;
        const selectedProfession = this.professions.find(prof => prof.id === this.data?.profession?.id);
        this.professionalForm.get('profession').setValue(selectedProfession || null);
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load professions.');
      }
    });
  }

  onSubmit(): void {
    if (this.professionalForm.valid) {
      const isNew = !this.data?.id;
      const result: UrbanPlannerExtendedNetworkDto = {
        ...this.data,
        ...this.professionalForm.value,
        index: isNew ? this.data.index : this.data.index
      };
      this.dialogRef.close(result);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
