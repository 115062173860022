import { JuristicPersonDetailDto } from 'src/app/services/property-matrixV2/models';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-juristic-person',
  templateUrl: './add-edit-juristic-person.component.html',
  styleUrls: ['./add-edit-juristic-person.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AddEditJuristicPersonComponent implements OnInit {

  juristicPersonForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    public _dialogRef: MatDialogRef<AddEditJuristicPersonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JuristicPersonDetailDto
  ) { }

  ngOnInit(): void {
    this.juristicPersonForm = this._formBuilder.group({
      fullName: [this.data?.fullName || '', [Validators.required, Validators.maxLength(250)]],
      registrationNumberOfEntity: [this.data?.registrationNumberOfEntity || '', [Validators.required, Validators.maxLength(250)]],
      vatNumber: [this.data?.vatNumber || '', [Validators.required, Validators.maxLength(250)]],
      legalRepresentative: this._formBuilder.group({
        title: [this.data?.legalRepresentative?.title || '', [Validators.maxLength(250)]],
        fullNames: [this.data?.legalRepresentative?.fullNames || '', [Validators.required, Validators.maxLength(250)]],
        surname: [this.data?.legalRepresentative?.surname || '', [Validators.required, Validators.maxLength(250)]],
        workPhoneNumber: [this.data?.legalRepresentative?.workPhoneNumber || '', [Validators.maxLength(10), CustomValidators.mobileValidator()]],
        mobilePhoneNumber: [this.data?.legalRepresentative?.mobilePhoneNumber || '', [Validators.required, Validators.maxLength(10), CustomValidators.mobileValidator()]],
        emailAddress: [this.data?.legalRepresentative?.emailAddress || '', [Validators.required, CustomValidators.emailValidator(), Validators.maxLength(250)]],
        confirmEmail: [this.data?.legalRepresentative?.confirmEmail || '', [Validators.required, CustomValidators.emailValidator(), Validators.maxLength(250)]],
        isSouthAfricanResident: [this.data?.legalRepresentative?.isSouthAfricanResident ?? null],
        identityNumber: [this.data?.legalRepresentative?.identityNumber || '', [Validators.maxLength(13)]],
        passportNumber: [this.data?.legalRepresentative?.passportNumber || '', [Validators.maxLength(20)]],
        confirmedConsent: [this.data?.legalRepresentative?.confirmedConsent || false, [Validators.required, CustomValidators.mustBeTrue()]]
      }, { validators: CustomValidators.emailMatchValidator() })
    });

    this.juristicPersonForm.get('legalRepresentative.isSouthAfricanResident').valueChanges.subscribe(value => {
      if (value === true) {
        this.juristicPersonForm.get('legalRepresentative.identityNumber').setValidators([Validators.required, Validators.maxLength(13)]);
        this.juristicPersonForm.get('legalRepresentative.passportNumber').clearValidators();
      } else if (value === false) {
        this.juristicPersonForm.get('legalRepresentative.passportNumber').setValidators([Validators.required, Validators.maxLength(20)]);
        this.juristicPersonForm.get('legalRepresentative.identityNumber').clearValidators();
      }
      this.juristicPersonForm.get('legalRepresentative.identityNumber').updateValueAndValidity();
      this.juristicPersonForm.get('legalRepresentative.passportNumber').updateValueAndValidity();
    });

    if (this.data) {
      this.patchFormValues(this.data);
    }
  }

  private patchFormValues(data: JuristicPersonDetailDto): void {
    this.juristicPersonForm.patchValue({
      fullName: data?.fullName,
      registrationNumberOfEntity: data?.registrationNumberOfEntity,
      vatNumber: data?.vatNumber,
      legalRepresentative: {
        title: data?.legalRepresentative?.title,
        fullNames: data?.legalRepresentative?.fullNames,
        surname: data?.legalRepresentative?.surname,
        workPhoneNumber: data?.legalRepresentative?.workPhoneNumber,
        mobilePhoneNumber: data?.legalRepresentative?.mobilePhoneNumber,
        emailAddress: data?.legalRepresentative?.emailAddress,
        confirmEmail: data?.legalRepresentative?.confirmEmail,
        isSouthAfricanResident: data?.legalRepresentative?.isSouthAfricanResident,
        identityNumber: data?.legalRepresentative?.identityNumber,
        passportNumber: data?.legalRepresentative?.passportNumber,
        confirmedConsent: data?.legalRepresentative?.confirmedConsent,
      }
    });
  }

  get isLegalRepresentativeSouthAfricanResident(): boolean {
    return this.juristicPersonForm.get('legalRepresentative.isSouthAfricanResident').value;
  }

  onSubmit(): void {
    if (this.juristicPersonForm.valid) {
      const isNew = !this.data?.id;
      const result: JuristicPersonDetailDto = {
        ...this.data,
        ...this.juristicPersonForm.value,
        legalRepresentative: { ...this.juristicPersonForm.value.legalRepresentative },
        index: isNew ? this.data?.index : this.data?.index
      };
      this._dialogRef.close(result);
    }
  }

  closeDialog(): void {
    this._dialogRef.close();
  }
}
